import { useParams, useSearchParams } from "react-router-dom";
import { s2GameId } from "./type-conversion";

/**
 * @returns {GameId}
 */
export const useGameIdFromRoute = () => {
  return s2GameId(useParams().gameId || "");
};

/**
 * @returns {("1"|"2"|undefined)}
 */
export const usePlayerFromRouteWithUndefined = () => {
  const [searchParams] = useSearchParams();
  const player = searchParams.get("player");

  if (player === "1") {
    return "1";
  }
  if (player === "2") {
    return "2";
  }

  return undefined;
};

/**
 * @returns {("1"|"2")}
 */
export const usePlayerFromRoute = () => {
  const [searchParams] = useSearchParams();
  const player = searchParams.get("player");

  if (player === "1") {
    return "1";
  }

  return "2";
};

/**
 * @returns {string}
 */
export const useStepFromRoute = () => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");

  return step || "";
};
