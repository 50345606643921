import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { s2Token } from "../util/type-conversion";
import { fetchGame, setOver, setTeam, simulateOver, startPlay, joinGame, fetchMyGames } from "../services/games";
import { useState } from "react";

/**
 * @param {import("../types/type").GameId} gameId
 * @returns {string[]}
 */
const GameById = (gameId) => ["game", gameId];
const MyGames = () => ["my-games"];

/**
 * @param {import("../types/type").GameId} gameId
 */
export const useGame = (gameId) => {
  const [enabled, setEnabled] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: GameById(gameId),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      const game = await fetchGame(token, gameId);

      if (!game) {
        return game;
      }

      if (game.phase === "MATCH_RESULTS") {
        setEnabled(false);
      }

      return game;
    },
    refetchInterval: 5000,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled,
  });
};

export const useMyGames = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: MyGames(),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      const games = await fetchMyGames(token);

      games.sort((a, b) => b.created - a.created);

      return games;
    },
  });
};

/**
 * @param {import("../types/type").GameId} gameId
 * @param {("1"|"2")} player
 */
export const usePickTeams = (gameId, player) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    /**
     * @param {import("../types/type").DieId[]} selections
     */
    mutationFn: async (selections) => {
      const token = s2Token(await getAccessTokenSilently());
      await setTeam(token, gameId, player, selections);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: GameById(gameId) });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

/**
 * @param {import("../types/type").GameId} gameId
 */
export const useStartPlay = (gameId) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      await startPlay(token, gameId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: GameById(gameId) });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

/**
 * @param {import("../types/type").GameId} gameId
 * @param {Player} player
 */
export const useSetOver = (gameId, player) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    /**
     * @param {object} request
     * @param {import("../types/type").DieId[]} request.selections
     * @param {import("../types/type").DieId[]} request.bowlerAttackDice
     */
    mutationFn: async ({ selections, bowlerAttackDice }) => {
      const token = s2Token(await getAccessTokenSilently());
      await setOver(token, gameId, player, selections, bowlerAttackDice);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: GameById(gameId) });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

/**
 * @param {import("../types/type").GameId} gameId
 */
export const useSimulateOver = (gameId) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      await simulateOver(token, gameId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: GameById(gameId) });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

/**
 * @param {import("../types/type").GameId} gameId
 */
export const useJoinGame = (gameId) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      await joinGame(token, gameId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: GameById(gameId) });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
