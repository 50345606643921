import axios from "axios";
import axiosRetry from "axios-retry";

const exponentialBackoff = (retryCount) => {
  return 2 ** retryCount * 1000 + Math.floor(Math.random() * 1000);
};

axiosRetry(axios, { retries: 5, retryDelay: exponentialBackoff });

axios.interceptors.request.use((config) => {
  // eslint-disable-next-line no-restricted-globals
  // config.headers["x-correlation-id"] = self.crypto.randomUUID();
  return config;
});

/**
 * @param {import("../types/type").TokenString} token
 * @returns
 */
export const optionsAuth = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  };
};

export const optionsNoAuth = () => {
  return {
    headers: {
      Accept: "application/json",
    },
  };
};

export const get = (url, config) => {
  return axios.get(url, config);
};

export const post = (url, data, config) => {
  return axios.post(url, data, config);
};
