import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import { GoHome } from "../RedirectToLastGame";
import { Chances } from "./Chances";
import { InningsSummary, InningsSummaryV2 } from "./InningsSummary";
import { MatchResult, MatchResultV2 } from "./MatchResult";
import { Scoreworm } from "./Scoreworm";
import { RunsBreakdown } from "./RunsBreakdown";
import { BowlerSummary, BowlerSummaryV2 } from "./BowlerSummary";
import { Partnerships } from "./Partnerships";

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const MatchComplete = ({ game }) => {
  return (
    <Box display="flex" m={0} p={1} flex={1}>
      <Stack direction="column" display="flex" flex="1">
        <Stack direction="row" display="flex" flex="1">
          <Box pr={0.5} display="flex" flex={1}>
            {game.config.useIndividualBallTracking ? (
              <InningsSummaryV2 game={game} title="Player 1" inningsNo={1} />
            ) : (
              <InningsSummary game={game} title="Player 1" inningsNo={1} />
            )}
          </Box>

          <Box display="flex" flex={1}>
            {game.config.useIndividualBallTracking ? (
              <InningsSummaryV2 game={game} title="Player 2" inningsNo={2} />
            ) : (
              <InningsSummary game={game} title="Player 2" inningsNo={2} />
            )}
          </Box>
        </Stack>

        <Box pt={1}>
          <Stack direction="row" display="flex" flex={1}>
            <Box pr={0.5} display="flex" flex={1}>
              {game.config.useIndividualBallTracking ? (
                <BowlerSummaryV2 game={game} inningsNo={1} />
              ) : (
                <BowlerSummary game={game} inningsNo={1} />
              )}
            </Box>

            <Box display="flex" flex={1}>
              {game.config.useIndividualBallTracking ? (
                <BowlerSummaryV2 game={game} inningsNo={2} />
              ) : (
                <BowlerSummary game={game} inningsNo={2} />
              )}
            </Box>
          </Stack>
        </Box>

        <Box pt={1}>
          {game.config.useIndividualBallTracking ? <MatchResultV2 game={game} /> : <MatchResult game={game} />}
        </Box>

        <Box pt={1}>
          <Scoreworm game={game} />
        </Box>

        <Box pt={1}>
          <RunsBreakdown game={game} />
        </Box>

        <Box pt={1}>
          <Chances game={game} />
        </Box>

        <Box pt={1}>
          <Partnerships game={game} inningsNo={1} />
        </Box>

        <Box pt={1}>
          <Partnerships game={game} inningsNo={2} />
        </Box>

        <Box pt={1}>
          <Paper>
            <Stack direction="row" display="flex" justifyContent="center">
              <Box p={1}>
                <GoHome />
              </Box>
            </Stack>
          </Paper>
        </Box>
      </Stack>
    </Box>
  );
};
