import React from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { DieFace } from "./DieFace";
import { dice } from "../models/dice";
import { colors, p1Colour, p2Colour } from "./colors";
import { calculateOverScore, getBattingTeam, getPlayer } from "../models/game";

/**
 * @param {object} props
 * @param {DiceRoll[]} props.diceRolls
 */
const RollDisplay = ({ diceRolls }) => {
  return (
    <Stack direction="row">
      {diceRolls.map((roll) => {
        const die = dice.find((die) => die.id === roll.id);
        if (!die) {
          return null;
        }

        const display = roll.face === "XXX" ? "3X" : roll.face === "XX" ? "2X" : roll.face;

        return (
          <Box key={roll.id} style={{ opacity: roll.cancelled ? 0.2 : 1.0 }}>
            <DieFace verysmall value={display} text={colors[die.type].text} color={colors[die.type].color} />
          </Box>
        );
      })}
    </Stack>
  );
};

/**
 * @param {object} props
 * @param {Over[]} props.innings
 */
export const OverSummary = ({ innings }) => (
  <Stack direction="column" display="flex" flex="1">
    <Box>
      <Stack direction="row" display="flex" flex="1">
        <Box display="flex" flex={1}>
          <Card style={{ display: "flex", flex: 1 }}>
            <Stack direction="column" display="flex" flex={1}>
              <Grid container>
                <Grid item xs={1}>
                  <Typography variant="body2" textAlign="left" pl={0.5}>
                    #
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2" textAlign="center">
                    BATTER
                  </Typography>
                </Grid>
                <Grid item xs={4.5}>
                  <Typography variant="body2" textAlign="center">
                    BOWLER
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography variant="body2" textAlign="right" pr={0.5}>
                    RUNS
                  </Typography>
                </Grid>
                {innings.toReversed().map((over) => (
                  <>
                    <Grid item xs={1}>
                      <Typography textAlign="left" pl={0.5}>
                        {over.number}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <RollDisplay diceRolls={over.batterRollsv2} />
                    </Grid>
                    <Grid item xs={4.5}>
                      <Stack direction="row" display="flex" justifyContent="space-between">
                        <RollDisplay diceRolls={over.bowlerAtackRollsv2} />
                        <RollDisplay diceRolls={over.bowlerRollsv2} />
                      </Stack>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Typography textAlign="right" pr={0.5}>
                        {over.runs}
                      </Typography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Stack>
          </Card>
        </Box>
      </Stack>
    </Box>
  </Stack>
);

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const OverSummaryV2 = ({ game }) => {
  const team = getBattingTeam(game);
  const innings = game.innings === 1 ? game.firstInnings : game.secondInnings;
  const batter = getPlayer(game, game.innings);
  const batterColour = batter === "1" ? p1Colour : p2Colour;
  const bowlerColour = batter === "1" ? p2Colour : p1Colour;

  return (
    <Stack direction="column" display="flex" flex="1">
      <Box>
        <Stack direction="row" display="flex" flex="1">
          <Box display="flex" flex={1}>
            <Card style={{ display: "flex", flex: 1 }}>
              <Stack direction="column" display="flex" flex={1}>
                <Grid container>
                  <Grid item xs={1} style={{ backgroundColor: batterColour }} p={0.5}>
                    <Typography variant="body2" textAlign="center" color="white">
                      #
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5} style={{ backgroundColor: batterColour }} p={0.5}>
                    <Typography variant="body2" textAlign="center" color="white">
                      RUNS
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ backgroundColor: batterColour }} p={0.5}>
                    <Typography variant="body2" textAlign="left" color="white">
                      BATTER
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5} style={{ backgroundColor: bowlerColour }} p={0.5}>
                    <Typography variant="body2" textAlign="left" color="white">
                      LEAD
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ backgroundColor: bowlerColour }} p={0.5}>
                    <Typography variant="body2" textAlign="left" color="white">
                      BOWLER
                    </Typography>
                  </Grid>
                  {innings
                    .map((over, i) => {
                      return (
                        <React.Fragment key={over.number}>
                          <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                            <Typography textAlign="center" variant="body2">
                              {over.number}
                            </Typography>
                          </Grid>
                          <Grid item xs={1.5} display="flex" justifyContent="center" alignItems="center">
                            <Typography
                              textAlign="right"
                              variant="body2"
                              style={{ fontVariantNumeric: "tabular-nums" }}
                            >
                              {calculateOverScore(team, innings, i)}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <RollDisplay diceRolls={over.batterRollsv2} />
                          </Grid>
                          <Grid item xs={1.5}>
                            <RollDisplay diceRolls={over.bowlerAtackRollsv2} />
                          </Grid>
                          <Grid item xs={4}>
                            <RollDisplay diceRolls={over.bowlerRollsv2} />
                          </Grid>
                        </React.Fragment>
                      );
                    })
                    .toReversed()}
                </Grid>
              </Stack>
            </Card>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
