import React from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import numeral from "numeral";
import { calculatePartnerships, makeInitialBattingPartnerships } from "../models/partnerships";
import {
  calculateBalls,
  calculateOverScore,
  calculateScore,
  calculateWickets,
  getBattingTeam,
  getTarget,
} from "../models/game";
import { ballByBall } from "../models/ball-by-ball";

const formatOver = (runs) => {
  if (runs === 0) {
    return "MAIDEN";
  }
  if (runs === 1) {
    return "1 run";
  }

  return `${runs} runs`;
};

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const GameSummary = ({ game }) => {
  const isSecond = game.innings === 2;
  const innings = game.innings === 1 ? game.firstInnings : game.secondInnings;
  const score = innings.reduce((t, v) => t + v.runs, 0);
  const partnerships = calculatePartnerships(innings);
  const wickets = partnerships.filter(({ out }) => out).length;

  const target = game.firstInnings.reduce((t, o) => t + o.runs, 0) + 1;
  const runsRequired = target - score;
  const balls = (20 - innings.length) * 6;

  const priorOver = innings[innings.length - 1];

  return (
    <Card>
      <Box p={1}>
        {!isSecond && (
          <Stack direction="row" display="flex" flex="1" justifyContent="space-between">
            <Typography>
              Score: {wickets}/{score}
            </Typography>
            <Typography>Run Rate: {numeral(score / innings.length).format("0.0")}</Typography>
            <Typography>Overs: {innings.length}</Typography>
          </Stack>
        )}
        {isSecond && (
          <Stack direction="row" display="flex" flex="1" justifyContent="space-between">
            <Typography>
              Score: {wickets}/{score}
            </Typography>
            <Typography>
              Target: {runsRequired} off {balls} balls
            </Typography>
          </Stack>
        )}
        {priorOver && (
          <Stack direction="row" display="flex" flex="1" justifyContent="space-between">
            <Typography>Last Over: {formatOver(priorOver.runs)}</Typography>
            <Typography>Wickets In Hand: {10 - wickets}</Typography>
          </Stack>
        )}
      </Box>
    </Card>
  );
};

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const GameSummaryV2 = ({ game }) => {
  const isSecond = game.innings === 2;
  const innings = game.innings === 1 ? game.firstInnings : game.secondInnings;

  const target = getTarget(game);
  const team = getBattingTeam(game);

  const partnerships = ballByBall(makeInitialBattingPartnerships(team), innings, target);
  const score = calculateScore(partnerships);
  const wickets = calculateWickets(partnerships);
  const balls = calculateBalls(partnerships);

  const runsRequired = (target || 0) - score;
  const ballsRemaining = 120 - balls;

  const runsfromPriorOver = calculateOverScore(team, innings, innings.length - 1);

  return (
    <Card>
      <Box p={1}>
        {!isSecond && (
          <Stack direction="row" display="flex" flex="1" justifyContent="space-between">
            <Typography>
              Score: {wickets}/{score}
            </Typography>
            <Typography>Run Rate: {numeral(score / innings.length).format("0.0")}</Typography>
            <Typography>Overs: {innings.length}</Typography>
          </Stack>
        )}
        {isSecond && (
          <Stack direction="row" display="flex" flex="1" justifyContent="space-between">
            <Typography>
              Score: {wickets}/{score}
            </Typography>
            <Typography>
              Target: {runsRequired} off {ballsRemaining} balls
            </Typography>
          </Stack>
        )}
        {runsfromPriorOver && (
          <Stack direction="row" display="flex" flex="1" justifyContent="space-between">
            <Typography>Last Over: {runsfromPriorOver}</Typography>
            <Typography>Wickets In Hand: {10 - wickets}</Typography>
          </Stack>
        )}
      </Box>
    </Card>
  );
};
