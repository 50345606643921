const SecureApi = "https://secure.dicecricketworldcup.com";

const endpoints = () => {
  const games = () => {
    const serverApi = SecureApi;

    return {
      create: () => {
        return `${serverApi}/v1/games`;
      },
      my: () => {
        return `${serverApi}/v1/games`;
      },
      /**
       * @param {import("../types/type").GameId} gameId
       */
      game: (gameId) => ({
        get: () => {
          return `${serverApi}/v1/games/${gameId}`;
        },
        join: () => {
          return `${serverApi}/v1/games/${gameId}/join`;
        },
        setTeam: () => {
          return `${serverApi}/v1/games/${gameId}/team`;
        },
        setOver: () => {
          return `${serverApi}/v1/games/${gameId}/over`;
        },
        simulate: () => {
          return `${serverApi}/v1/games/${gameId}/simulate`;
        },
        play: () => {
          return `${serverApi}/v1/games/${gameId}/play`;
        },
      }),
    };
  };

  const users = () => {
    const serverApi = SecureApi;

    return {
      me: () => {
        return `${serverApi}/v1/me`;
      },
      /**
       * @param {import("../types/type").UserId} userId
       */
      user: (userId) => ({
        get: () => {
          return `${serverApi}/v1/users/${userId}`;
        },
      }),
    };
  };

  return {
    games,
    users,
  };
};

export default endpoints;
