import React from "react";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import logo from "../assets/logo.png";
import { Die } from "../screens/Die";
import { dice } from "../models/dice";
import { Scorecard } from "../screens/Scorecard";
import { BowlerDicePerOver } from "../screens/BowlerDicePerOver";
import { GoHome } from "../RedirectToLastGame";

const w1 = dice.find((die) => die.id === "W11");
const y1 = dice.find((die) => die.id === "Y04");
const o1 = dice.find((die) => die.id === "O01");
const r1 = dice.find((die) => die.id === "R04");

const partnerships = [
  { id: 1, runs: 23, chances: 5, maxDice: 5, out: false, chancesLost: 2 },
  { id: 2, runs: 0, chances: 5, maxDice: 5, out: false, chancesLost: 0 },
];

export const Rules = () => (
  <Box p={1}>
    <Stack direction="column" display="flex" flex="1">
      <Box>
        <Paper>
          <Stack direction="row" justifyContent="center" display="flex">
            <img src={logo} alt="" style={{ width: "25%", maxWidth: 400, height: "auto" }} />
          </Stack>

          <Box p={1}>
            <Typography textAlign="center" variant="h3">
              HOW TO PLAY
            </Typography>
          </Box>
          <Box p={1} display="flex" justifyContent="center">
            <Typography textAlign="center" maxWidth="70ch">
              In Dice Cricket World Cup you play as a team trying to win a 20-over game of cricket. Each over you'll
              choose which dice to roll in the hope of scoring runs, countering your opponent or getting your opponent
              out. Each team will play an innings of up to 20 overs and the team with the highest score wins!
            </Typography>
          </Box>
        </Paper>
      </Box>

      <Box pt={1}>
        <Paper>
          <Typography textAlign="center" variant="h6">
            Batting
          </Typography>
          <Stack direction="column" p={1} display="flex" justifyContent="center" alignItems="center">
            <Typography textAlign="left" maxWidth="70ch">
              When you bat in Dice Cricket World Cup you'll be trying to score as many runs as you can from the dice you
              throw. At the start of an innings you will be able to throw up to 5 dice at a time. As you lose wickets
              you will also lose how many dice you can throw. Your opportunity to score ends after 20 throws of the dice
              or when your opponent has taken all ten of your wickets.
            </Typography>
            <Typography textAlign="left" maxWidth="70ch" pt={1}>
              Below are four different dice, one from each group. The gray die is the safest with a guaranteed 1 run but
              with a maximum of 2 runs. The yellow, orange and red dice have better opportunities for scoring but come
              with risk which we will talk about next.
            </Typography>
            <Box display="flex" justifyContent="center" flex={1}>
              <Grid container spacing={1}>
                <Grid item xs={{ xs: "6", md: 3 }}>
                  <Die id={w1.id} faces={w1.faces} type={w1.type} disabled />
                </Grid>
                <Grid item xs={{ xs: "6", md: 3 }}>
                  <Die id={y1.id} faces={y1.faces} type={y1.type} disabled />
                </Grid>
                <Grid item xs={{ xs: "6", md: 3 }}>
                  <Die id={o1.id} faces={o1.faces} type={o1.type} disabled />
                </Grid>
                <Grid item xs={{ xs: "6", md: 3 }}>
                  <Die id={r1.id} faces={r1.faces} type={r1.type} disabled />
                </Grid>
              </Grid>
            </Box>
            <Typography textAlign="left" maxWidth="70ch" pt={1}>
              Wickets are lost by running out of chances. Some dice have an X or a XX on some of their faces. If you get
              this result then your current batting pair will lose 1 or 2 chances respectively. When a pair has lost all
              their chances, they are out. The first pairs have 5 chances each while later pairs only have 1. The loss
              of each wicket will mean that you will lose access to one of your dice when you have lost 9 wickets, you
              will only have 2 dice left.
            </Typography>
            <Typography textAlign="left" maxWidth="70ch" pt={1}>
              In the example below, the first pair has 23 runs so far and has lost two chances. The batting player can
              throw up 5 dice on their turn.
            </Typography>
          </Stack>
        </Paper>
      </Box>

      <Box pt={1}>
        <Scorecard
          game={{ battingPlayer: "1" }}
          partnerships={partnerships}
          selectionsBatter={["R04", "Y04"]}
          wickets={0}
        />
      </Box>

      <Box pt={1}>
        <Paper>
          <Typography textAlign="center" variant="h6">
            Bowling
          </Typography>
          <Stack direction="column" p={1} display="flex" justifyContent="center" alignItems="center">
            <Typography textAlign="left" maxWidth="70ch">
              When bowling you will have two options, to bowl defensively or to attack. When you bowl defensively, every
              number you roll will cancel out one of the batters dice if they also roll that number.
            </Typography>
            <Typography textAlign="left" maxWidth="70ch" pt={1}>
              For example, if the batter rolls a 6 and you roll a 6, that dice will be ignored for scoring. If however,
              the batter rolled a 4 and you rolled a 6, then your die will be added to the batters pool and they would
              have scored 10. Any X that you roll will count as zero and will not count against the batter's chances.
            </Typography>
            <Typography textAlign="left" maxWidth="70ch" pt={1}>
              When you want to attack you choose which dice to attack with (you may attack with some dice and defend
              with others). The attacking dice are given to the batter to roll. Any runs they score are scored but any X
              or XX will be counted against them and could cost them a wicket.
            </Typography>
          </Stack>
        </Paper>
      </Box>

      <Box pt={1}>
        <BowlerDicePerOver
          game={{
            config: { batterForInningsKnown: true },
            batter1st: "1",
            batter2nd: "2",
            battingPlayer: "1",
            innings: 1,
            firstInnings: [],
            diceUsedPerOver: [],
          }}
        />
      </Box>

      <Box pt={1}>
        <Paper>
          <Stack direction="column" p={1} display="flex" justifyContent="center" alignItems="center">
            <Typography textAlign="left" maxWidth="70ch" pt={1}>
              As a bowler you have a couple of other constraints. The first is you can't roll the same dice two overs in
              a row. The second is that you need roll a certain number of dice per over over the course of the innings.
              In the table above the bowler has to roll 6 dice two times per game and 2 dice, six times per game.
            </Typography>
          </Stack>
        </Paper>
      </Box>

      <Box pt={1}>
        <Paper>
          <Stack direction="column" p={1} display="flex" justifyContent="center" alignItems="center">
            <Typography textAlign="left" maxWidth="70ch" pt={1}>
              Dice Cricket World Cup is a game of risk and taking opportunities. At the start of each match you will
              pick the 11 dice you want to use in the game. You will choose how many and which dice to roll each over
              and when bowling, whether to attack or defend. The choice is all yours. Let's hope the win is too.
            </Typography>
          </Stack>
        </Paper>
      </Box>

      <Box pt={1}>
        <Paper>
          <Stack direction="row" display="flex" justifyContent="center">
            <Box p={1}>
              <GoHome />
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Stack>
  </Box>
);
