import { s2DieId } from "../util/type-conversion";

export const Dot = "•";
export const X = "X";
export const XX = "XX";
export const XXX = "XXX";

/**
 * @type {DiceFaces[]}
 */
export const Chances = [X, XX, XXX];

/**
 * @type {DiceCandidate[]}
 */
const TestDice = [
  {
    id: s2DieId("X01"),
    faces: [X, X, X, X, X, X],
    type: "red",
    display: false,
    hasXXX: false,
  },
  {
    id: s2DieId("X02"),
    faces: [XX, XX, XX, XX, XX, XX],
    type: "red",
    display: false,
    hasXXX: false,
  },
  { id: s2DieId("X03"), faces: [1, 1, 1, 1, 1, 1], type: "red", display: false, hasXXX: false },
  { id: s2DieId("X04"), faces: [2, 2, 2, 2, 2, 2], type: "red", display: false, hasXXX: false },
  { id: s2DieId("X05"), faces: [3, 3, 3, 3, 3, 3], type: "red", display: false, hasXXX: false },
  { id: s2DieId("X06"), faces: [4, 4, 4, 4, 4, 4], type: "red", display: false, hasXXX: false },
  { id: s2DieId("X07"), faces: [5, 5, 5, 5, 5, 5], type: "red", display: false, hasXXX: false },
  { id: s2DieId("X08"), faces: [6, 6, 6, 6, 6, 6], type: "red", display: false, hasXXX: false },
  {
    id: s2DieId("X09"),
    faces: [Dot, Dot, Dot, Dot, Dot, Dot],
    type: "red",
    display: false,
    hasXXX: false,
  },
];

/**
 * @type {DiceCandidate[]}
 */
export const dice = [
  { id: s2DieId("W11"), faces: [1, 1, 1, 1, 1, 2], type: "white", display: true, hasXXX: false },
  { id: s2DieId("W10"), faces: [Dot, 1, 1, 1, 2, 2], type: "white", display: true, hasXXX: false },
  { id: s2DieId("W09"), faces: [Dot, 1, 1, 1, 1, 3], type: "white", display: true, hasXXX: false },
  { id: s2DieId("W08"), faces: [Dot, 1, 1, 1, 1, 3], type: "white", display: false, hasXXX: false },
  { id: s2DieId("W07"), faces: [Dot, Dot, 1, 2, 2, 2], type: "white", display: true, hasXXX: false },
  { id: s2DieId("W06"), faces: [Dot, Dot, 1, 1, 2, 3], type: "white", display: true, hasXXX: false },
  { id: s2DieId("W04"), faces: [Dot, Dot, Dot, 2, 2, 3], type: "white", display: true, hasXXX: false },
  { id: s2DieId("W03"), faces: [Dot, Dot, Dot, 1, 3, 3], type: "white", display: true, hasXXX: false },
  { id: s2DieId("W05"), faces: [Dot, Dot, 1, 1, 1, 4], type: "white", display: true, hasXXX: false },
  { id: s2DieId("W02"), faces: [Dot, Dot, Dot, 1, 2, 4], type: "white", display: true, hasXXX: false },
  { id: s2DieId("W01"), faces: [Dot, Dot, Dot, Dot, 3, 4], type: "white", display: true, hasXXX: false },

  { id: s2DieId("Y11"), faces: [2, 2, 2, 2, 2, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y10"), faces: [1, 1, 2, 3, 3, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y09"), faces: [1, 2, 2, 2, 3, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y06"), faces: [Dot, 2, 2, 3, 3, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y08"), faces: [1, 1, 2, 2, 4, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y07"), faces: [1, 1, 1, 3, 4, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y05"), faces: [Dot, 2, 2, 2, 4, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y04"), faces: [Dot, 1, 2, 3, 4, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y03"), faces: [Dot, 1, 1, 4, 4, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y02"), faces: [Dot, Dot, 3, 3, 4, X], type: "yellow", display: true, hasXXX: false },
  { id: s2DieId("Y01"), faces: [Dot, Dot, 2, 4, 4, X], type: "yellow", display: true, hasXXX: false },

  { id: s2DieId("O10"), faces: [3, 3, 3, 3, X, X], type: "orange", display: true, hasXXX: false },
  { id: s2DieId("O09"), faces: [2, 3, 3, 4, X, X], type: "orange", display: true, hasXXX: false },
  { id: s2DieId("O08"), faces: [1, 3, 4, 4, X, X], type: "orange", display: true, hasXXX: false },
  { id: s2DieId("O07"), faces: [2, 2, 4, 4, X, X], type: "orange", display: true, hasXXX: false },
  { id: s2DieId("O06"), faces: [Dot, 4, 4, 4, X, X], type: "orange", display: true, hasXXX: false },
  { id: s2DieId("O05"), faces: [1, 2, 3, 6, X, X], type: "orange", display: true, hasXXX: false },
  { id: s2DieId("O04"), faces: [Dot, 3, 3, 6, X, X], type: "orange", display: true, hasXXX: false },
  { id: s2DieId("O03"), faces: [1, 1, 4, 6, X, X], type: "orange", display: true, hasXXX: false },
  { id: s2DieId("O02"), faces: [Dot, 2, 4, 6, X, X], type: "orange", display: true, hasXXX: false },
  { id: s2DieId("O01"), faces: [Dot, Dot, 6, 6, X, X], type: "orange", display: true, hasXXX: false },

  { id: s2DieId("R01"), faces: [4, 4, 6, X, X, X], type: "red", display: true, hasXXX: false },
  { id: s2DieId("R02"), faces: [2, 6, 6, X, X, X], type: "red", display: true, hasXXX: false },
  { id: s2DieId("R03"), faces: [4, 6, 6, X, X, XX], type: "red", display: true, hasXXX: false },
  { id: s2DieId("R04"), faces: [6, 6, 6, X, XX, XX], type: "red", display: true, hasXXX: false },
  { id: s2DieId("R05"), faces: [6, 6, 6, 4, XX, XXX], type: "red", display: true, hasXXX: true },
  { id: s2DieId("R06"), faces: [6, 6, 6, 6, XXX, XXX], type: "red", display: true, hasXXX: true },

  { id: s2DieId("B01"), faces: [6, 6, 6, 4, XX, XXX], type: "black", display: false, hasXXX: false },
  { id: s2DieId("B02"), faces: [6, 6, 6, 6, XXX, XXX], type: "black", display: false, hasXXX: false },
  ...TestDice,
];
