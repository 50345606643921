import React, { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Paper, Stack, Button, CircularProgress } from "@mui/material";
// @ts-ignore
import logo from "../assets/logo.png";
import { CenterOnPage } from "../layout/CenterOnPage";
import { SaveGameId } from "../RedirectToLastGame";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const onClickHandler = useCallback(() => loginWithRedirect(), [loginWithRedirect]);

  return (
    <Button variant="contained" onClick={onClickHandler} color="primary">
      Login
    </Button>
  );
};

export const LoginScreen = () => {
  return (
    <SaveGameId>
      <CenterOnPage>
        <Box p={2} maxWidth="80ch">
          <Paper>
            <Box p={2} display="flex">
              <Stack direction="column">
                <Stack direction="row" justifyContent="center" display="flex">
                  <img src={logo} alt="" style={{ width: "50%", maxWidth: 400, height: "auto" }} />
                </Stack>
                <Box p={4} justifyContent="center" display="flex">
                  <LoginButton />
                </Box>
              </Stack>
            </Box>
          </Paper>
        </Box>
      </CenterOnPage>
    </SaveGameId>
  );
};

export const ProtectedRoute = ({ Screen }) => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return (
      <CenterOnPage>
        <CircularProgress />
      </CenterOnPage>
    );
  }

  return isAuthenticated ? <Screen /> : <LoginScreen />;
};
