import React, { useEffect, useState } from "react";
import { Box, Paper, CircularProgress, TextField, Typography, Button, Stack } from "@mui/material";
import { CenterOnPage } from "./layout/CenterOnPage";
import { useUpdateShortName, useUserProfile } from "./queries/user";
import { GoHome } from "./RedirectToLastGame";

export const Profile = () => {
  const { data: profile } = useUserProfile();
  const [shortName, setShortName] = useState("");
  const updateShortName = useUpdateShortName();

  useEffect(() => {
    if (!profile) {
      return;
    }
    setShortName(profile.shortName);
  }, [profile]);

  if (!profile) {
    return (
      <CenterOnPage>
        <CircularProgress />
      </CenterOnPage>
    );
  }

  const onChangeHandler = async (event) => {
    setShortName(`${event.target.value}`.substring(0, 4).toUpperCase());
  };

  const onSaveHandler = async () => {
    await updateShortName.mutate(shortName);
  };

  const isDisabled = () => {
    if (shortName.length === 0) {
      return true;
    }
    if (shortName === profile.shortName) {
      return true;
    }

    return false;
  };

  return (
    <CenterOnPage>
      <Box display="flex" justifyContent="center">
        <Box p={2} minWidth="80ch">
          <Paper>
            <Box p={2}>
              <Typography>Short Name e.g. AUS</Typography>
              <Box pt={1}>
                <TextField value={shortName} onChange={onChangeHandler}></TextField>
              </Box>
              <Box pt={1}>
                <Button disabled={isDisabled()} variant="outlined" onClick={onSaveHandler}>
                  SAVE
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box pt={1} pb={8}>
        <Paper>
          <Stack direction="row" display="flex" justifyContent="center">
            <Box p={1}>
              <GoHome />
            </Box>
          </Stack>
        </Paper>
      </Box>
    </CenterOnPage>
  );
};
