import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DateTime } from "luxon";
import { CenterOnPage } from "../layout/CenterOnPage";
import { useNavigate } from "react-router-dom";
import { StartNewGame } from "./StartNewGame";
import { useLocalStorage } from "usehooks-ts";
import { useMyGames } from "../queries/games";
import { usePublicProfile } from "../queries/user";

/**
 * @type {Config}
 */
const defaultConfig = {
  bowlerFirstDieIsOnlyAttack: true,
  bowlerMax4OversAsAttackDie: true,
  useIndividualBallTracking: true,
  attackDiceOnlyCountX: false,
  dotsDontCancel: true,
  solo: false,
  aiMode: "RANDOM",
  noXXX: true,
  batterForInningsKnown: true,
};

/**
 * @type {Config}
 */
const HideInProd = {
  bowlerFirstDieIsOnlyAttack: false,
  bowlerMax4OversAsAttackDie: false,
  useIndividualBallTracking: false,
  attackDiceOnlyCountX: true,
  dotsDontCancel: false,
  solo: false,
  aiMode: "RANDOM",
  noXXX: false,
  batterForInningsKnown: true,
};

const ConfigLabels = {
  bowlerFirstDieIsOnlyAttack: "Current bowler is only attack die",
  bowlerMax4OversAsAttackDie: "Current bowler max 4 overs",
  useIndividualBallTracking: "Show individual batter scores",
  attackDiceOnlyCountX: "On attack dice, X goes to batter",
  dotsDontCancel: "dot balls don't cancel each other out",
  noXXX: "Remove XXX dice from play",
  solo: "Play against the AI",
};

/**
 * @param {GameSummary} game
 */
const formatResult = (game) => {
  if (game.phase === "MATCH_RESULTS") {
    return game.result;
  }

  if (game.phase === "INNINGS_RESULTS") {
    return "INNINGS BREAK";
  }

  if (game.phase === "PICK_TEAMS") {
    return "PRE-GAME";
  }

  if (game.innings === 1) {
    return "1ST INNINGS";
  }

  return "2ND INNINGS";
};

/**
 * @param {GameSummary} game
 * @param {PrivateUserProfile?} [player1]
 * @param {PrivateUserProfile?} [player2]
 */
const formatFirstInningsScore = (game, player1, player2) => {
  const p1 = !player1 || player1.shortName.length === 0 ? "P1" : player1.shortName;
  const p2 = !player2 || player2.shortName.length === 0 ? "P2" : player2.shortName;

  if (game.phase === "PICK_TEAMS") {
    return "";
  }

  if (game.batter1st === "1") {
    return `${p1}: ${game.innings1Score}`;
  }

  return `${game.solo ? "AI" : p2}: ${game.innings1Score}`;
};

/**
 * @param {GameSummary} game
 * @param {PrivateUserProfile?} [player1]
 * @param {PrivateUserProfile?} [player2]
 */
const formatSecondInningsScore = (game, player1, player2) => {
  const p1 = !player1 || player1.shortName.length === 0 ? "P1" : player1.shortName;
  const p2 = !player2 || player2.shortName.length === 0 ? "P2" : player2.shortName;

  if (game.phase === "PICK_TEAMS") {
    return "";
  }
  if (game.innings === 1) {
    return "";
  }
  if (!game.innings2Score) {
    return "";
  }

  if (game.batter2nd === "1") {
    return `${p1}: ${game.innings2Score}`;
  }

  return `${game.solo ? "AI" : p2}: ${game.innings2Score}`;
};

/**
 *
 * @param {object} props
 * @param {GameSummary} props.game
 * @param {number} props.i
 */
const GameRow = ({ game, i }) => {
  const { data: player1 } = usePublicProfile(game.player1);
  const { data: player2 } = usePublicProfile(game.player2);

  return (
    <React.Fragment key={game.id}>
      <Grid item xs={2} p={0.5} style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}>
        <Link component={RouterLink} to={`/games/${game.id}`}>
          <Typography textAlign="center">{DateTime.fromMillis(game.created).toFormat("d MMM @ HH:mm")}</Typography>
        </Link>
      </Grid>
      <Grid item xs={1} p={0.5} style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}>
        <Typography textAlign="center">{game.solo ? "SOLO" : "PVP"}</Typography>
      </Grid>
      <Grid item xs={2.5} p={0.5} style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}>
        <Typography textAlign="center">{formatFirstInningsScore(game, player1, player2)}</Typography>
      </Grid>
      <Grid item xs={2.5} p={0.5} style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}>
        <Typography textAlign="center">{formatSecondInningsScore(game, player1, player2)}</Typography>
      </Grid>
      <Grid item xs={4} p={0.5} style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}>
        <Typography textAlign="center">{formatResult(game)}</Typography>
      </Grid>
    </React.Fragment>
  );
};

export const HomePage = () => {
  const navigate = useNavigate();
  const [savedGameId] = useLocalStorage("com.dicecricketworldcup.gameId", "");
  const [config, setConfig] = useState(defaultConfig);
  const { data: games = [], isLoading } = useMyGames();

  const loadFromStorage = () => {
    navigate(`/games/${savedGameId}`);
  };

  const goToRules = () => {
    navigate(`/rules`);
  };

  const changeSetting = (setting) => {
    setConfig((currentConfig) => ({ ...currentConfig, [setting]: !currentConfig[setting] }));
  };

  const handleAiModeChange = (event) => {
    setConfig((currentConfig) => ({ ...currentConfig, aiMode: event.target.value }));
  };

  return (
    <CenterOnPage>
      <Box display="flex" justifyContent="center">
        <Box p={2} maxWidth="80ch">
          <Paper>
            <Box p={2}>
              <Stack direction="row" display="flex" justifyContent="center">
                <Button variant="outlined" onClick={goToRules}>
                  How To Play
                </Button>
              </Stack>
            </Box>
          </Paper>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box p={2} maxWidth="80ch">
          <Paper>
            <Box p={2}>
              <Stack direction="row" display="flex" justifyContent="center">
                <StartNewGame config={config} />
              </Stack>
            </Box>
            {
              <Stack direction="column" display="flex" justifyContent="center">
                <Typography textAlign="center">Config</Typography>
                <Box p={1}>
                  <FormGroup>
                    {Object.keys(defaultConfig)
                      .filter((setting) => !HideInProd[setting])
                      .map((setting) => (
                        <FormControlLabel
                          key={setting}
                          control={
                            <Checkbox
                              defaultChecked={config[setting]}
                              value={config[setting]}
                              onChange={() => changeSetting(setting)}
                            />
                          }
                          label={ConfigLabels[setting]}
                        />
                      ))}
                    {config.solo && (
                      <>
                        <InputLabel id="select-ai">AI</InputLabel>
                        <Select
                          labelId="select-ai"
                          id="ai-options"
                          value={config.aiMode}
                          label="Age"
                          onChange={handleAiModeChange}
                        >
                          <MenuItem value={"RANDOM"}>RANDOM</MenuItem>
                          <MenuItem value={"BATTER"}>BATTER</MenuItem>
                          <MenuItem value={"BOWLER"}>BOWLER</MenuItem>
                        </Select>
                      </>
                    )}
                  </FormGroup>
                </Box>
              </Stack>
            }
          </Paper>
        </Box>
      </Box>

      <Box p={2}>
        <Paper>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box p={1}>
              <Grid container>
                <Grid item xs={2} p={0.5}>
                  <Typography variant="body2" fontWeight="bold" textAlign="center">
                    DATE
                  </Typography>
                </Grid>
                <Grid item xs={1} p={0.5}>
                  <Typography variant="body2" fontWeight="bold" textAlign="center">
                    MODE
                  </Typography>
                </Grid>
                <Grid item xs={2.5} p={0.5}>
                  <Typography variant="body2" fontWeight="bold" textAlign="center">
                    1ST
                  </Typography>
                </Grid>
                <Grid item xs={2.5} p={0.5}>
                  <Typography variant="body2" fontWeight="bold" textAlign="center">
                    2ND
                  </Typography>
                </Grid>
                <Grid item xs={4} p={0.5}>
                  <Typography variant="body2" fontWeight="bold" textAlign="center">
                    RESULT
                  </Typography>
                </Grid>
                {games.map((game, i) => (
                  <GameRow game={game} key={game.id} i={i} />
                ))}
              </Grid>
            </Box>
          )}
        </Paper>
      </Box>

      <Box p={2}>
        <Paper>
          <Box p={2}>
            <Stack direction="row" display="flex" justifyContent="center">
              <Button variant="outlined" onClick={loadFromStorage}>
                Load last game
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </CenterOnPage>
  );
};
