import React from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { dice } from "../models/dice";
import { DieDisplay } from "./Die";
import { makeInitialBattingPartnerships } from "../models/partnerships";
import { p1Colour, p2Colour } from "./colors";
import { getPlayer, getTarget, getTeam } from "../models/game";
import { ballByBall } from "../models/ball-by-ball";
import { partnershipChartOptions2 } from "./options";
import { Bar } from "react-chartjs-2";

/**
 * @param {number} batter1
 * @param {number} batter2
 */

export const buildPartnershipChartDatav2 = (batter1, batter2) => {
  const barData = {
    datasets: [
      {
        type: "bar",
        data: [batter1],
        backgroundColor: p1Colour,
        borderRadius: 5,
      },
      {
        type: "bar",
        data: [batter2],
        backgroundColor: p2Colour,
        borderRadius: 5,
      },
    ],
    labels: [1],
  };

  return barData;
};
/**
 * @param {Game} game
 * @param {number} inningsNo
 */

export const buildPartnershipData = (game, inningsNo) => {
  const team = getTeam(game, getPlayer(game, inningsNo));
  const target = inningsNo === 1 ? undefined : getTarget(game);
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;
  const partnerships = ballByBall(makeInitialBattingPartnerships(team), innings, target);

  return partnerships.filter((partnership) => partnership.balls > 0);
};
/**
 * @param {Game} game
 * @param {number} inningsNo
 */

export const getLargestPartnershipContribution = (game, inningsNo) => {
  const team = getTeam(game, getPlayer(game, inningsNo));
  const target = inningsNo === 1 ? undefined : getTarget(game);
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;
  const partnerships = ballByBall(makeInitialBattingPartnerships(team), innings, target);

  const largest = partnerships
    .filter((partnership) => partnership.balls > 0)
    .map((partnership) => [partnership.runsBatter1, partnership.runsBatter2])
    .reduce((arr, v) => [...arr, ...v], [])
    .toSorted((a, b) => b - a)[0];

  return largest + 1;
};
/**
 * @param {object} props
 * @param {Game} props.game
 * @param {number} props.inningsNo
 */

export const Partnerships = ({ game, inningsNo }) => {
  const size = getLargestPartnershipContribution(game, inningsNo);

  return (
    <Card>
      <Box p={1}>
        <Box pb={2}>
          <Typography variant="body2">PARTNERSHIPS ({inningsNo === 1 ? "1ST INNINGS" : "2ND INNINGS"})</Typography>
        </Box>
        <Grid container rowSpacing={1}>
          {buildPartnershipData(game, inningsNo).map(
            ({ id, runsBatter1, runsBatter2, batter1, batter2, ballsBatter1, ballsBatter2 }) => {
              const die1 = dice.find((die) => die.id === batter1.id);
              const die2 = dice.find((die) => die.id === batter2.id);
              if (!die1 || !die2) {
                return null;
              }

              return (
                <React.Fragment key={id}>
                  <Grid item xs={3}>
                    <Stack
                      sx={{ height: 25 }}
                      direction="row"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <DieDisplay id={die1.id} verysmall faces={die1.faces} type={die1.type} />
                      <Typography>
                        {runsBatter1} ({ballsBatter1})
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ height: 25 }} display="flex" justifyContent="center" alignItems="center">
                      <Bar
                        // @ts-ignore
                        data={buildPartnershipChartDatav2(-runsBatter1, runsBatter2)}
                        // @ts-ignore
                        options={partnershipChartOptions2(size)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack
                      sx={{ height: 25 }}
                      direction="row"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <DieDisplay id={die2.id} verysmall faces={die2.faces} type={die2.type} />
                      <Typography>
                        {runsBatter2} ({ballsBatter2})
                      </Typography>
                    </Stack>
                  </Grid>
                </React.Fragment>
              );
            }
          )}
        </Grid>
      </Box>
    </Card>
  );
};
