import { Box, Checkbox, Icon, Stack, Typography } from "@mui/material";
import React from "react";
import { colors, playerColour } from "./colors";
import { DieFace, DieFaceDisplay } from "./DieFace";
import { usePlayerFromRoute } from "../util/route-queries";
import { Lock, PriorityHigh, BatteryFull, Battery0Bar, Battery80, Battery50, Battery20 } from "@mui/icons-material";

const BatteryAsOversRemaining = ({ timesUsed }) => {
  if (timesUsed === 0) {
    return <BatteryFull />;
  }
  if (timesUsed === 1) {
    return <Battery80 />;
  }
  if (timesUsed === 2) {
    return <Battery50 />;
  }
  if (timesUsed === 3) {
    return <Battery20 />;
  }

  return <Battery0Bar />;
};

/**
 * @param {object} props
 * @param {string} props.id
 * @param {(string|number)[]} props.faces
 * @param {string} props.type
 * @param {boolean} [props.left]
 * @param {boolean} [props.right]
 * @param {Function} [props.selectDie]
 * @param {boolean} [props.selected]
 * @param {boolean} [props.small]
 * @param {boolean} [props.isBowler]
 * @param {boolean} [props.preselected]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.showAttack]
 * @param {boolean} [props.isAttack]
 * @param {boolean} [props.inaccessible]
 * @param {Function} [props.toggleAttackDie]
 * @param {boolean} [props.noIcon]
 * @param {boolean} [props.verysmall]
 * @param {boolean} [props.isLeadDie]
 * @param {boolean} [props.leadSelected]
 * @param {boolean} [props.bowlerMax4OversAsAttackDie]
 * @param {boolean} [props.hasSelectedLead]
 * @param {number} [props.order]
 * @param {number} [props.timesUsed]
 * @param {boolean} [props.noBackground]
 */
export const Die = ({
  id,
  isBowler,
  small,
  faces,
  type,
  left = false,
  right = false,
  selectDie = () => null,
  selected = false,
  preselected = false,
  disabled = false,
  showAttack = false,
  isAttack = false,
  inaccessible = false,
  verysmall = false,
  toggleAttackDie,
  order,
  noIcon = false,
  isLeadDie = false,
  leadSelected = false,
  bowlerMax4OversAsAttackDie = false,
  hasSelectedLead = false,
  noBackground = false,
  timesUsed,
}) => {
  const player = usePlayerFromRoute();

  const onClickHandler = () => {
    if (disabled) {
      return;
    }
    if (preselected) {
      return;
    }
    if (inaccessible) {
      return;
    }
    if (timesUsed === 4 && bowlerMax4OversAsAttackDie && !hasSelectedLead) {
      return;
    }

    selectDie(id);
  };

  const fullTileSelected = !left && !right && selected;
  const lockedSlotBackgroundColour = "#eee";
  const playerBackgroundColour = fullTileSelected ? playerColour(player) : lockedSlotBackgroundColour;
  const dieColour = isLeadDie ? `${playerBackgroundColour}33` : lockedSlotBackgroundColour;

  return (
    <Box p={0.5} style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
      <Stack
        direction="row"
        flex={0}
        display="flex"
        borderRadius={1}
        style={{
          backgroundColor: noBackground ? "transparent" : dieColour,
          borderColor: noBackground ? "transparent" : playerBackgroundColour,
          borderWidth: 2,
          borderStyle: "solid",
          opacity: inaccessible ? 0.2 : 1.0,
          cursor: inaccessible || preselected || disabled ? "not-allowed" : "pointer",
        }}
      >
        {isBowler && !inaccessible && (
          <>
            {bowlerMax4OversAsAttackDie ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <BatteryAsOversRemaining timesUsed={timesUsed} />
              </Box>
            ) : (
              <Checkbox
                style={{ padding: 0 }}
                disabled={(isBowler && !showAttack) || type === "white" || leadSelected}
                checked={isAttack}
                onChange={() => toggleAttackDie && toggleAttackDie(id)}
              />
            )}
          </>
        )}
        <Stack
          direction="row"
          flex={0}
          alignItems="center"
          justifyContent="flex-start"
          p={0}
          pt={0.5}
          pb={0.5}
          onClick={onClickHandler}
        >
          {!!order && <Typography p={0.25}>#{order}</Typography>}
          {left && <Checkbox checked={selected} />}
          {inaccessible && <Lock />}
          {preselected && <PriorityHigh />}
          {!inaccessible && !preselected && !noIcon && <Icon />}
          {faces
            .map((face) => (isBowler && !isAttack && face === "X" ? "•" : face))
            .map((face) => (isBowler && !isAttack && face === "XX" ? "•" : face))
            .map((face) => (isBowler && !isAttack && face === "XXX" ? "•" : face))
            .map((face) => (face === "XX" ? "2X" : face))
            .map((face) => (face === "XXX" ? "3X" : face))
            .map((face, i) => {
              return (
                <DieFace
                  key={`${id}-${face}-${i}`}
                  variant="body2"
                  value={face}
                  text={colors[type].text}
                  color={colors[type].color}
                  style={{}}
                  small={small}
                  verysmall={verysmall}
                />
              );
            })}
          {right && <Checkbox checked={selected} />}
        </Stack>
      </Stack>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {string} props.id
 * @param {(string|number)[]} props.faces
 * @param {string} props.type
 */
export const DieDisplay = ({ id, faces, type }) => {
  return (
    <Box>
      <Stack direction="row" flex={0} alignItems="center" justifyContent="flex-start" p={0}>
        <DieFaceDisplay
          key={`${id}`}
          variant="body2"
          value={faces
            .map((face) => (face === "XX" ? "2X" : face))
            .map((face) => (face === "XXX" ? "3X" : face))
            .join(" ")}
          text={colors[type].text}
          color={colors[type].color}
        />
      </Stack>
    </Box>
  );
};
