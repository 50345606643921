import { s2PartnershipId } from "../util/type-conversion";

/**
 * @type {PartnershipReference[]}
 */
export const Partnerships = [
  { id: s2PartnershipId(1), chances: 5, maxDice: 5 },
  { id: s2PartnershipId(2), chances: 5, maxDice: 5 },
  { id: s2PartnershipId(3), chances: 4, maxDice: 5 },
  { id: s2PartnershipId(4), chances: 3, maxDice: 4 },
  { id: s2PartnershipId(5), chances: 3, maxDice: 4 },
  { id: s2PartnershipId(6), chances: 2, maxDice: 4 },
  { id: s2PartnershipId(7), chances: 2, maxDice: 3 },
  { id: s2PartnershipId(8), chances: 1, maxDice: 3 },
  { id: s2PartnershipId(9), chances: 1, maxDice: 2 },
  { id: s2PartnershipId(10), chances: 1, maxDice: 2 },
];

/**
 *
 * @param {Partnership} a
 * @param {Partnership} b
 * @returns {number}
 */
export const byOrder = (a, b) => a.id - b.id;
