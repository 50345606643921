import React from "react";
import { Card, Stack, Typography } from "@mui/material";
import { calculatePartnerships, makeInitialBattingPartnerships } from "../models/partnerships";
import { p1Colour, p2Colour } from "./colors";
import { ballByBall } from "../models/ball-by-ball";
import { calculateScore, calculateWickets, getTarget } from "../models/game";

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const MatchResult = ({ game }) => {
  const partnerships2 = calculatePartnerships(game.secondInnings);
  const p1Score = game.firstInnings.reduce((t, over) => t + over.runs, 0);
  const p2Score = game.secondInnings.reduce((t, over) => t + over.runs, 0);
  const p2Wickets = partnerships2.filter(({ out }) => out).length;

  const batFirstPlayer = game.battingPlayer === "1" ? "2" : "1";

  const matchTied = p1Score === p2Score;
  const firstInningsWins = !matchTied && p1Score > p2Score;
  const scoreMargin = p1Score - p2Score;
  const wicketsMargin = 10 - p2Wickets;
  const runs = scoreMargin === 1 ? "RUN" : "RUNS";
  const wickets = wicketsMargin === 1 ? "WICKET" : "WICKETS";
  const margin = firstInningsWins ? `${p1Score - p2Score} ${runs}` : `${10 - p2Wickets} ${wickets}`;
  const p1Winner = `PLAYER 1 WINS BY ${margin}`;
  const p2Winner = `PLAYER 2 WINS BY ${margin}`;
  const matchTiedText = "MATCH TIED";

  const playerWinsText = firstInningsWins && batFirstPlayer === "1" ? p1Winner : p2Winner;
  const matchResult = matchTied ? matchTiedText : playerWinsText;

  const firstInningsColour = game.battingPlayer === "1" ? p2Colour : p1Colour;
  const secondInningsColour = game.battingPlayer === "1" ? p1Colour : p2Colour;

  return (
    <Card>
      <Stack
        direction="row"
        display="flex"
        flex="1"
        justifyContent="center"
        p={1}
        style={{ backgroundColor: firstInningsWins ? firstInningsColour : secondInningsColour }}
      >
        <Typography variant="h5" textAlign="center" color="white">
          {matchResult}
        </Typography>
      </Stack>
    </Card>
  );
};

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const MatchResultV2 = ({ game }) => {
  const batFirstTeam = game.batter1st === "1" ? game.player1Selections : game.player2Selections;
  const batSecondTeam = game.batter1st === "1" ? game.player2Selections : game.player1Selections;

  const target = getTarget(game);
  const batFirst = ballByBall(makeInitialBattingPartnerships(batFirstTeam), game.firstInnings);
  const batSecond = ballByBall(makeInitialBattingPartnerships(batSecondTeam), game.secondInnings, target);

  const p1Score = calculateScore(batFirst);
  const p2Score = calculateScore(batSecond);
  const p2Wickets = calculateWickets(batSecond);

  const oldBatFirstPlayer = game.battingPlayer === "1" ? "2" : "1";
  const batFirstPlayer = game.config.batterForInningsKnown ? game.batter1st : oldBatFirstPlayer;

  const matchTied = p1Score === p2Score;
  const firstInningsWins = !matchTied && p1Score > p2Score;
  const scoreMargin = p1Score - p2Score;
  const wicketsMargin = 10 - p2Wickets;
  const runs = scoreMargin === 1 ? "RUN" : "RUNS";
  const wickets = wicketsMargin === 1 ? "WICKET" : "WICKETS";
  const margin = firstInningsWins ? `${p1Score - p2Score} ${runs}` : `${10 - p2Wickets} ${wickets}`;
  const p1Winner = `PLAYER 1 WINS BY ${margin}`;
  const p2Winner = `PLAYER 2 WINS BY ${margin}`;
  const matchTiedText = "MATCH TIED";

  const playerWinsText = firstInningsWins && batFirstPlayer === "1" ? p1Winner : p2Winner;
  const matchResult = matchTied ? matchTiedText : playerWinsText;

  const firstInningsColour = game.battingPlayer === "1" ? p2Colour : p1Colour;
  const secondInningsColour = game.battingPlayer === "1" ? p1Colour : p2Colour;

  return (
    <Card>
      <Stack
        direction="row"
        display="flex"
        flex="1"
        justifyContent="center"
        p={1}
        style={{ backgroundColor: firstInningsWins ? firstInningsColour : secondInningsColour }}
      >
        <Typography variant="h5" textAlign="center" color="white">
          {matchResult}
        </Typography>
      </Stack>
    </Card>
  );
};
