import { s2PartnershipId } from "../util/type-conversion";

const { Partnerships } = require("../reference/Partnerships");
const { RunsPerBonusChance } = require("./chances");

export const MaxPartnerships = 10;
const AllOut = 100;

/**
 * @typedef {object} TrackingData
 * @property {number} runs
 * @property {number} balls
 * @property {number} chancesLost
 * @property {number} wicketThreshold
 * @property {Partnership[]} partnerships
 */

/**
 * @param {number} id
 * @returns {PartnershipReference}
 */
const getReferencePartnership = (id) => {
  // @ts-ignore
  return Partnerships.find((ref) => ref.id === id);
};

/**
 * @param {number} runs
 * @returns {number}
 */
export const calculateBonusChances = (runs) => Math.floor(runs / RunsPerBonusChance);

/**
 * @param {TrackingData} trackingData
 * @returns {boolean}
 */
const isOut = (trackingData) => {
  return trackingData.chancesLost >= trackingData.wicketThreshold + calculateBonusChances(trackingData.runs);
};

/**
 * @param {number} pNumber partnership number
 * @param {TrackingData} trackingData
 * @returns {TrackingData}
 */
const onWicketLost = (pNumber, trackingData) => {
  const bonusChances = calculateBonusChances(trackingData.runs);
  const refOut = getReferencePartnership(pNumber);
  const refNew = getReferencePartnership(pNumber + 1);
  const partnership = {
    ...refOut,
    balls: trackingData.balls,
    runs: trackingData.runs,
    out: true,
    chancesLost: refOut.chances + bonusChances,
  };

  return {
    ...trackingData,
    runs: 0,
    balls: 0,
    partnerships: trackingData.partnerships.concat(partnership),
    wicketThreshold: !refNew ? AllOut : trackingData.wicketThreshold + refNew.chances + bonusChances,
  };
};

/**
 * @param {number} pNumber partnership number
 * @param {TrackingData} trackingData
 * @returns {TrackingData}
 */
const recurseWickets = (pNumber, trackingData) => {
  if (isOut(trackingData)) {
    const outTrackingData = onWicketLost(pNumber, trackingData);

    return recurseWickets(pNumber + 1, outTrackingData);
  }

  return trackingData;
};

/**
 * @param {TrackingData} trackingData
 * @returns {number}
 */
const partnershipNumber = (trackingData) => trackingData.partnerships.length + 1;

/**
 * @param {TrackingData} inTrackingData
 * @param {Over} over
 * @returns {TrackingData}
 */
const processOver = (inTrackingData, over) => {
  const updatedTrackingData = {
    ...inTrackingData,
    runs: inTrackingData.runs + over.runs,
    balls: inTrackingData.balls + 6,
    chancesLost: inTrackingData.chancesLost + over.chances,
  };

  return recurseWickets(partnershipNumber(updatedTrackingData), updatedTrackingData);
};

/**
 * @param {Over[]} innings
 * @returns {Partnership[]}
 * @deprecated
 */
export const calculatePartnerships = (innings) => {
  /**
   * @type {TrackingData}
   */
  const initialTackingData = {
    runs: 0,
    balls: 0,
    chancesLost: 0,
    wicketThreshold: getReferencePartnership(1).chances,
    partnerships: [],
  };

  const outTrackingData = innings.reduce(processOver, initialTackingData);

  if (outTrackingData.partnerships.length < MaxPartnerships) {
    const refCurrent = getReferencePartnership(outTrackingData.partnerships.length + 1);
    const chancesRemaining = outTrackingData.wicketThreshold - outTrackingData.chancesLost;
    outTrackingData.partnerships.push({
      ...refCurrent,
      runs: outTrackingData.runs,
      balls: outTrackingData.balls,
      out: false,
      chancesLost: refCurrent.chances - chancesRemaining,
    });
  }

  const partnershipsSoFar = outTrackingData.partnerships.map((p) => p.id);
  const pending = Partnerships.filter((p) => !partnershipsSoFar.includes(p.id));

  return outTrackingData.partnerships.concat(
    pending.map((p) => ({ ...p, balls: 0, runs: 0, out: false, chancesLost: 0 }))
  );
};

/**
 * @type {BattingPartnership[]}
 */
const BattingPartnerships = [
  {
    id: s2PartnershipId(1),
    chances: 5,
    maxDice: 5,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
  {
    id: s2PartnershipId(2),
    chances: 5,
    maxDice: 5,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
  {
    id: s2PartnershipId(3),
    chances: 4,
    maxDice: 5,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
  {
    id: s2PartnershipId(4),
    chances: 3,
    maxDice: 4,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
  {
    id: s2PartnershipId(5),
    chances: 3,
    maxDice: 4,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
  {
    id: s2PartnershipId(6),
    chances: 2,
    maxDice: 4,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
  {
    id: s2PartnershipId(7),
    chances: 2,
    maxDice: 3,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
  {
    id: s2PartnershipId(8),
    chances: 1,
    maxDice: 3,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
  {
    id: s2PartnershipId(9),
    chances: 1,
    maxDice: 2,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
  {
    id: s2PartnershipId(10),
    chances: 1,
    maxDice: 2,
    balls: 0,
    runs: 0,
    out: false,
    isBatter1OnStrike: true,
    chancesLost: 0,
    runsBatter1: 0,
    runsBatter2: 0,
    ballsBatter1: 0,
    ballsBatter2: 0,
  },
];

/**
 * @param {import("../types/type").DieId[]} team
 * @returns {BattingPartnership[]}
 */
export const makeInitialBattingPartnerships = (team) => {
  return [...BattingPartnerships].map((partnership, i) => ({
    ...partnership,
    batter1:
      i > 0
        ? undefined
        : {
            balls: 0,
            id: team[i],
            out: false,
            runs: 0,
          },
    batter2: {
      balls: 0,
      id: team[i + 1],
      out: false,
      runs: 0,
    },
  }));
};
