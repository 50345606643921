import React, { useEffect, useState } from "react";
import { Box, Button, Card, CircularProgress, Grid, Paper, Stack, Typography } from "@mui/material";
import shuffle from "lodash/shuffle";
import { dice } from "../models/dice";
import { Die } from "./Die";
import { useGameIdFromRoute, usePlayerFromRoute } from "../util/route-queries";
import { useGame, useStartPlay } from "../queries/games";
import { CenterOnPage } from "../layout/CenterOnPage";
import { haveBothPlayerSelectedTeams } from "../models/game";
import { GoHome } from "../RedirectToLastGame";
import { usePublicProfile } from "../queries/user";

/**
 * @type {import("../types/type").DieId[]}
 */
const initialSelections = [];

const WaitingForOtherPlayer = () => {
  const gameId = useGameIdFromRoute();
  const player = usePlayerFromRoute();
  const { data: game } = useGame(gameId);
  const { data: opponent } = usePublicProfile(game && game.player2);

  return (
    <CenterOnPage>
      <Paper>
        <Box p={2}>
          <Typography textAlign="center">Waiting for other player to pick team.</Typography>
          {player === "1" && (
            <Typography textAlign="center">
              {game && game.player2
                ? `${opponent ? opponent.shortName : "Oppenent"} has joined.`
                : "Player 2 yet to join."}
            </Typography>
          )}
        </Box>
      </Paper>
      <Box pt={1} pb={8}>
        <Paper>
          <Stack direction="row" display="flex" justifyContent="center">
            <Box p={1}>
              <GoHome />
            </Box>
          </Stack>
        </Paper>
      </Box>
    </CenterOnPage>
  );
};

export const PickTeams = ({ onPhaseComplete }) => {
  const player = usePlayerFromRoute();
  const gameId = useGameIdFromRoute();
  const { data: game, isLoading } = useGame(gameId);
  const [remoteSelections, updateRemoteSelections] = useState(initialSelections);
  const [selections, updateSelections] = useState(initialSelections);
  const startPlay = useStartPlay(gameId);

  useEffect(() => {
    if (!game) {
      return;
    }

    if (haveBothPlayerSelectedTeams(game) && game.phase === "PICK_TEAMS") {
      startPlay.mutate();
    }
  }, [game, startPlay]);

  useEffect(() => {
    if (!game) {
      return;
    }

    const remoteSelections = player === "1" ? game.player1Selections : game.player2Selections;
    updateRemoteSelections(remoteSelections);
    updateSelections(remoteSelections);
  }, [game, player]);

  /**
   * @param {import("../types/type").DieId} id
   */
  const onDieClicked = (id) => {
    if (selections.find((selection) => selection === id)) {
      updateSelections((existing) => existing.filter((selection) => selection !== id));
      return;
    }
    if (selections.length === 11) {
      return;
    }

    updateSelections((existing) => existing.concat(id));
  };

  const onPlayClicked = () => {
    onPhaseComplete(selections);
  };

  const onRandomSelected = () => {
    if (!game) {
      return;
    }

    const diceToPickFrom = dice
      .filter(({ display }) => display)
      .filter(({ hasXXX }) => (game.config.noXXX ? !hasXXX : true));

    const randomSelections = shuffle(diceToPickFrom)
      .map((die) => die.id)
      .slice(0, 11);

    updateSelections(randomSelections);
  };

  const locked = remoteSelections.length === 11;

  if (locked) {
    return <WaitingForOtherPlayer />;
  }

  if (!game) {
    return (
      <CenterOnPage>
        <CircularProgress />
      </CenterOnPage>
    );
  }

  return (
    <Box display="flex" m={0}>
      <Box flex={1} p={1}>
        <Card>
          <Stack direction="column">
            <Box p={1}>
              <Typography variant="h5" textAlign="center">
                AVAILABLE PLAYERS
              </Typography>
            </Box>
          </Stack>
          {isLoading ? (
            <Box p={4} display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <Stack direction="column" justifyContent="center" alignItems="center">
              <Grid container>
                {dice
                  .filter(({ display }) => display)
                  .filter(({ hasXXX }) => (game.config.noXXX ? !hasXXX : true))
                  .map((die) => (
                    <Grid item xs={6} key={die.id}>
                      <Die
                        id={die.id}
                        faces={die.faces}
                        type={die.type}
                        disabled={locked}
                        selectDie={onDieClicked}
                        selected={!!selections.find((selection) => selection === die.id)}
                        order={selections.findIndex((selection) => selection === die.id) + 1}
                        noIcon={!!selections.find((selection) => selection === die.id)}
                        small
                      />
                    </Grid>
                  ))}
              </Grid>
            </Stack>
          )}
        </Card>
        <Box pt={1}>
          <Card>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box p={1} display="flex" justifyContent="center" alignItems="center">
                <Button disabled={locked} variant="contained" color="error" onClick={onRandomSelected}>
                  RANDOM
                </Button>
              </Box>
              <Typography>{selections.length} / 11 SELECTED</Typography>
              <Box p={1} display="flex" justifyContent="center" alignItems="center">
                <Button variant="contained" disabled={locked || selections.length !== 11} onClick={onPlayClicked}>
                  PLAY
                </Button>
              </Box>
            </Stack>
          </Card>
        </Box>
        <Box pt={1}>
          <Card>
            <Typography>{JSON.stringify(game.config, null, 2)}</Typography>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
