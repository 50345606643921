import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGameIdFromRoute } from "./util/route-queries";
import { useLocalStorage } from "usehooks-ts";
import { Button } from "@mui/material";

export const RedirectToLastGame = ({ children }) => {
  const routeGameId = useGameIdFromRoute();
  const [savedGameId] = useLocalStorage("com.dicecricketworldcup.gameId", "");
  const navigate = useNavigate();

  useEffect(() => {
    if (routeGameId === savedGameId) {
      return;
    }
    if (savedGameId.length === 0) {
      setTimeout(() => {
        navigate(`/games/${savedGameId}`);
      }, 0);
      return;
    }

    setTimeout(() => {
      navigate(`/games/${savedGameId}`);
    }, 0);
  }, [routeGameId, savedGameId, navigate]);

  return children;
};

export const SaveGameId = ({ children }) => {
  const routeGameId = useGameIdFromRoute();
  const [savedGameId, setGameId] = useLocalStorage("com.dicecricketworldcup.gameId", "");

  useEffect(() => {
    if (savedGameId !== routeGameId) {
      setGameId(routeGameId);
    }
  }, [routeGameId, savedGameId, setGameId]);

  return children;
};

export const GoHome = () => {
  const [, setGameId] = useLocalStorage("com.dicecricketworldcup.gameId", "");
  const navigate = useNavigate();

  const startNewGameHandler = () => {
    setGameId("");
    navigate(`/new-game`);
  };

  return (
    <Button variant="outlined" onClick={startNewGameHandler}>
      Go Home
    </Button>
  );
};
