import React from "react";
import { Box, Button, Paper, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { CenterOnPage } from "./layout/CenterOnPage";
import { p2Colour } from "./screens/colors";
import { GoHome } from "./RedirectToLastGame";
import { useJoinGame } from "./queries/games";
import { useGameIdFromRoute } from "./util/route-queries";

export const PlayerSelection = () => {
  const gameId = useGameIdFromRoute();
  const [, setSearchParams] = useSearchParams();
  const joinGame = useJoinGame(gameId);

  const selectPlayer = (player) => () => {
    joinGame.mutate();
    setSearchParams({ player });
  };

  return (
    <CenterOnPage>
      <Stack direction="row">
        <Box p={2}>
          <Paper>
            <Box p={2} style={{ backgroundColor: p2Colour }}>
              <Button onClick={selectPlayer(2)} style={{ color: "white" }}>
                Take Player 2
              </Button>
            </Box>
          </Paper>
        </Box>

        <Box pt={2}>
          <Paper>
            <Box p={2}>
              <GoHome />
            </Box>
          </Paper>
        </Box>
      </Stack>
    </CenterOnPage>
  );
};
