import React, { useEffect, useState } from "react";
import { Box, Paper, Stack } from "@mui/material";
import { calculatePartnerships } from "../models/partnerships";
import { usePlayerFromRoute } from "../util/route-queries";
import { getSelectionsForPlayer } from "../models/game";
import { BowlerDisplay } from "./BowlerDisplay";
import { BatterDisplay } from "./BatterDisplay";
import { GameSummary, GameSummaryV2 } from "./GameSummary";
import { CurrentPartnership, CurrentPartnershipV2, CurrentPartnershipV3, Scorecard, ScorecardV2 } from "./Scorecard";
import { OverSummary, OverSummaryV2 } from "./OverSummary";
import { GoHome } from "../RedirectToLastGame";
import { BowlerDicePerOver } from "./BowlerDicePerOver";
import { BowlerSummary } from "./BowlerSummary";
import { Partnerships } from "./Partnerships";
import { OverAnimation } from "./OverAnimation";

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const PlayGame = ({ game }) => {
  const [showOverSummary, setShowOver] = useState(false);
  const player = usePlayerFromRoute();
  const isBatting = player === game.battingPlayer;
  const selectionsPlayer = getSelectionsForPlayer(game, player);
  const selectionsOpponent = getSelectionsForPlayer(game, player === "1" ? "2" : "1");
  const selectionsBatter = isBatting ? selectionsPlayer : selectionsOpponent;
  const innings = game.innings === 1 ? game.firstInnings : game.secondInnings;

  const partnerships = calculatePartnerships(innings);
  const wickets = partnerships.filter(({ out }) => out).length;

  const closeDisplayHandler = () => {
    setShowOver(false);
  };

  const showDisplayHandler = () => {
    setShowOver(true);
  };

  useEffect(() => {
    if (game.over === 0) {
      return;
    }

    setShowOver(true);
  }, [game.over]);

  return (
    <Box display="flex" m={0} p={1} pt={0} flex={1}>
      <Stack direction="column" display="flex" flex="1">
        {showOverSummary && <OverAnimation game={game} closeDisplay={closeDisplayHandler} />}

        <Box pt={1} onClick={showDisplayHandler}>
          {game.config.useIndividualBallTracking ? <GameSummaryV2 game={game} /> : <GameSummary game={game} />}
        </Box>

        <Box pt={1}>
          {game.config.useIndividualBallTracking ? (
            <CurrentPartnershipV2 game={game} />
          ) : (
            <CurrentPartnership
              partnerships={partnerships}
              selectionsBatter={selectionsBatter}
              game={game}
              wickets={wickets}
            />
          )}
        </Box>

        {game.config.useIndividualBallTracking && (
          <Box pt={1}>
            <CurrentPartnershipV3 game={game} />
          </Box>
        )}

        {!game.inningsComplete && (
          <Box pt={1}>
            <BowlerDicePerOver game={game} />
          </Box>
        )}

        <Box pt={1}>{isBatting ? <BatterDisplay game={game} /> : <BowlerDisplay game={game} />}</Box>

        {!game.inningsComplete && (
          <Box pt={1}>
            {game.config.useIndividualBallTracking ? <OverSummaryV2 game={game} /> : <OverSummary innings={innings} />}
          </Box>
        )}

        <Box pt={1}>
          {game.config.useIndividualBallTracking ? (
            <ScorecardV2 game={game} />
          ) : (
            <Scorecard partnerships={partnerships} selectionsBatter={selectionsBatter} game={game} wickets={wickets} />
          )}
        </Box>

        <Box pt={1}>
          <BowlerSummary game={game} inningsNo={game.innings} />
        </Box>

        <Box pt={1}>
          <Partnerships game={game} inningsNo={game.innings} />
        </Box>

        <Box pt={1} pb={8}>
          <Paper>
            <Stack direction="row" display="flex" justifyContent="center">
              <Box p={1}>
                <GoHome />
              </Box>
            </Stack>
          </Paper>
        </Box>
      </Stack>
    </Box>
  );
};
