import { Box, Typography } from "@mui/material";
import React from "react";

export const DieFace = ({ small, verysmall, variant, value, text, color, style }) => {
  const smolSize = verysmall ? "0.5vw" : "1vw";
  const size = small || verysmall ? smolSize : 8;

  return (
    <Box p={0.5} pl={0.25} pr={0.25}>
      <Box
        p={1}
        boxShadow={1}
        style={{ height: size, width: size, backgroundColor: color }}
        borderRadius={1}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Typography variant={variant} style={{ color: text, ...style }}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export const DieFaceDisplay = ({ variant, value, text, color }) => {
  return (
    <Box p={0.5} pl={0.25} pr={0.25}>
      <Box
        boxShadow={1}
        style={{ width: 100, backgroundColor: color }}
        borderRadius={1}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Typography variant={variant} style={{ color: text }}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
