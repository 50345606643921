import React from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { p1Colour, p2Colour } from "./colors";
import { MinDicePerOver, getPlayer } from "../models/game";
import { Done, DoneAll, RadioButtonUnchecked } from "@mui/icons-material";

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const BowlerDicePerOver = ({ game }) => {
  const batter = getPlayer(game, game.innings);
  const bowlerColour = batter === "1" ? p2Colour : p1Colour;

  return (
    <Card>
      <Box p={1} style={{ backgroundColor: bowlerColour }}>
        <Typography variant="body2" textAlign="center" color="white">
          BOWLER DICE PER OVER
        </Typography>
      </Box>
      <Box p={1}>
        <Stack direction="row" display="flex">
          <Stack direction="column" display="flex" flex={1}>
            {Object.keys(MinDicePerOver).map((count) => (
              <Typography key={count} variant="body2" textAlign="center" flex={1}>
                {count}
              </Typography>
            ))}
          </Stack>
          <Stack direction="column" display="flex" flex={6}>
            {Object.keys(MinDicePerOver).map((count) => {
              const done = game.diceUsedPerOver.filter((qty) => qty === Number.parseInt(count, 10)).length;
              const remaining = MinDicePerOver[count] - done;
              const alldone = MinDicePerOver[count] === done;

              const color = game.bowlingPlayer === "1" ? p1Colour : p2Colour;

              return (
                <Typography
                  key={count}
                  textAlign="center"
                  flex={1}
                  flexDirection="row"
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  alignItems="center"
                  color={color}
                >
                  {done > 0 &&
                    !alldone &&
                    Array(done)
                      .fill(0)
                      .map((_, i) => <Done key={i} style={{ height: 18 }} />)}
                  {remaining > 0 &&
                    !alldone &&
                    Array(remaining)
                      .fill(0)
                      .map((_, i) => <RadioButtonUnchecked viewBox="2 2 22 22" key={i} style={{ height: 18 }} />)}

                  {alldone && <DoneAll style={{ height: 18 }} />}
                </Typography>
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};
