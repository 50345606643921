import endpoints from "./endpoints";
import { get, optionsAuth, post } from "./network";

/**
 * @param {import("../types/type").TokenString} token
 * @param {Config} config
 * @returns {Promise<Game>}
 */
export const createGame = async (token, config) => {
  const { games } = endpoints();
  const url = games().create();

  const response = await post(url, config, optionsAuth(token));

  return response.data;
};

/**
 * @param {import("../types/type").TokenString} token
 * @param {import("../types/type").GameId} gameId
 * @returns {Promise<Game>}
 */
export const fetchGame = async (token, gameId) => {
  const { games } = endpoints();
  const url = games().game(gameId).get();

  const response = await get(url, optionsAuth(token));

  return response.data;
};

/**
 * @param {import("../types/type").TokenString} token
 * @returns {Promise<GameSummary[]>}
 */
export const fetchMyGames = async (token) => {
  const { games } = endpoints();
  const url = games().my();

  const response = await get(url, optionsAuth(token));

  return response.data;
};

/**
 * @param {import("../types/type").TokenString} token
 * @param {import("../types/type").GameId} gameId
 * @param {Player} player
 * @param {import("../types/type").DieId[]} selections
 * @returns {Promise<Game>}
 */
export const setTeam = async (token, gameId, player, selections) => {
  const { games } = endpoints();
  const url = games().game(gameId).setTeam();

  /**
   * @type {SetTeamRequest}
   */
  const body = {
    player,
    selections,
  };

  const response = await post(url, body, optionsAuth(token));

  return response.data;
};

/**
 * @param {import("../types/type").TokenString} token
 * @param {import("../types/type").GameId} gameId
 * @returns {Promise<Game>}
 */
export const startPlay = async (token, gameId) => {
  const { games } = endpoints();
  const url = games().game(gameId).play();

  const response = await post(url, {}, optionsAuth(token));

  return response.data;
};

/**
 * @param {import("../types/type").TokenString} token
 * @param {import("../types/type").GameId} gameId
 * @param {Player} player
 * @param {import("../types/type").DieId[]} selections
 * @param {import("../types/type").DieId[]} bowlerAttackDice
 * @returns {Promise<Game>}
 */
export const setOver = async (token, gameId, player, selections, bowlerAttackDice) => {
  const { games } = endpoints();
  const url = games().game(gameId).setOver();

  /**
   * @type {SetOverRequest}
   */
  const body = {
    player,
    selections,
    bowlerAttackDice,
  };

  const response = await post(url, body, optionsAuth(token));

  return response.data;
};

/**
 * @param {import("../types/type").TokenString} token
 * @param {import("../types/type").GameId} gameId
 * @returns {Promise<Game>}
 */
export const simulateOver = async (token, gameId) => {
  const { games } = endpoints();
  const url = games().game(gameId).simulate();

  const response = await post(url, {}, optionsAuth(token));

  return response.data;
};

/**
 * @param {import("../types/type").TokenString} token
 * @param {import("../types/type").GameId} gameId
 * @returns {Promise<Game>}
 */
export const joinGame = async (token, gameId) => {
  const { games } = endpoints();
  const url = games().game(gameId).join();

  const response = await post(url, {}, optionsAuth(token));

  return response.data;
};
