import { ballByBall } from "../models/ball-by-ball";
import { calculateScore, calculateWickets, getBatterForInnings, getPlayer, getTarget, getTeam } from "../models/game";
import { calculatePartnerships, makeInitialBattingPartnerships } from "../models/partnerships";
import { p1Colour, p2Colour } from "./colors";

export function buildChartData(firstInnings, secondInnings) {
  const p1OversWithWickets = firstInnings.map((_, i) => {
    const pBefore = i === 0 ? [] : calculatePartnerships(firstInnings.slice(0, i));
    const pThis = calculatePartnerships(firstInnings.slice(0, i + 1));

    const wBefore = pBefore.filter(({ out }) => out).length;
    const wThis = pThis.filter(({ out }) => out).length;

    const wicketsInOver = wThis - wBefore;

    const scoreAtOver = pThis.reduce((t, over) => t + over.runs, 0);

    return wicketsInOver === 0 ? null : scoreAtOver;
  });

  const p1OversWithWickets2 = firstInnings.map((_, i) => {
    const pBefore = i === 0 ? [] : calculatePartnerships(firstInnings.slice(0, i));
    const pThis = calculatePartnerships(firstInnings.slice(0, i + 1));

    const wBefore = pBefore.filter(({ out }) => out).length;
    const wThis = pThis.filter(({ out }) => out).length;

    const wicketsInOver = wThis - wBefore;

    const scoreAtOver = pThis.reduce((t, over) => t + over.runs, 0);

    return wicketsInOver <= 1 ? null : scoreAtOver - 20;
  });

  const p1OversWithWickets3 = firstInnings.map((_, i) => {
    const pBefore = i === 0 ? [] : calculatePartnerships(firstInnings.slice(0, i));
    const pThis = calculatePartnerships(firstInnings.slice(0, i + 1));

    const wBefore = pBefore.filter(({ out }) => out).length;
    const wThis = pThis.filter(({ out }) => out).length;

    const wicketsInOver = wThis - wBefore;

    const scoreAtOver = pThis.reduce((t, over) => t + over.runs, 0);

    return wicketsInOver <= 2 ? null : scoreAtOver - 40;
  });

  const p2OversWithWickets = secondInnings.map((_, i) => {
    const pBefore = i === 0 ? [] : calculatePartnerships(secondInnings.slice(0, i));
    const pThis = calculatePartnerships(secondInnings.slice(0, i + 1));

    const wBefore = pBefore.filter(({ out }) => out).length;
    const wThis = pThis.filter(({ out }) => out).length;

    const wicketsInOver = wThis - wBefore;

    const scoreAtOver = pThis.reduce((t, over) => t + over.runs, 0);

    return wicketsInOver === 0 ? null : scoreAtOver;
  });

  const p2OversWithWickets2 = secondInnings.map((_, i) => {
    const pBefore = i === 0 ? [] : calculatePartnerships(secondInnings.slice(0, i));
    const pThis = calculatePartnerships(secondInnings.slice(0, i + 1));

    const wBefore = pBefore.filter(({ out }) => out).length;
    const wThis = pThis.filter(({ out }) => out).length;

    const wicketsInOver = wThis - wBefore;

    const scoreAtOver = pThis.reduce((t, over) => t + over.runs, 0);

    return wicketsInOver <= 1 ? null : scoreAtOver - 20;
  });

  const p2OversWithWickets3 = secondInnings.map((_, i) => {
    const pBefore = i === 0 ? [] : calculatePartnerships(secondInnings.slice(0, i));
    const pThis = calculatePartnerships(secondInnings.slice(0, i + 1));

    const wBefore = pBefore.filter(({ out }) => out).length;
    const wThis = pThis.filter(({ out }) => out).length;

    const wicketsInOver = wThis - wBefore;

    const scoreAtOver = pThis.reduce((t, over) => t + over.runs, 0);

    return wicketsInOver <= 1 ? null : scoreAtOver - 40;
  });

  const data = {
    datasets: [
      {
        type: "scatter",
        borderColor: p1Colour,
        backgroundColor: p1Colour,
        data: [null].concat(p1OversWithWickets),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: p1Colour,
        backgroundColor: p1Colour,
        data: [null].concat(p1OversWithWickets2),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: p1Colour,
        backgroundColor: p1Colour,
        data: [null].concat(p1OversWithWickets3),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: p2Colour,
        backgroundColor: p2Colour,
        data: [null].concat(p2OversWithWickets),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: p2Colour,
        backgroundColor: p2Colour,
        data: [null].concat(p2OversWithWickets2),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: p2Colour,
        backgroundColor: p2Colour,
        data: [null].concat(p2OversWithWickets3),
        pointRadius: 8,
        label: false,
      },
      {
        pointStyle: false,
        type: "line",
        borderColor: p1Colour,
        backgroundColor: p1Colour,
        data: firstInnings.reduce((tally, over) => [...tally, tally[tally.length - 1] + over.runs], [0]),
        label: "Player 1",
      },
      {
        pointStyle: false,
        type: "line",
        borderColor: p2Colour,
        backgroundColor: p2Colour,
        data: secondInnings.reduce((tally, over) => [...tally, tally[tally.length - 1] + over.runs], [0]),
        label: "Player 2",
      },
    ],
    labels: [0].concat(
      Array(20)
        .fill(0)
        .map((_, i) => i + 1)
    ),
  };
  return data;
}

/**
 * @param {Game} game
 * @param {number} inningsNo
 */
const calculateRunsForEachOver = (game, inningsNo) => {
  const player = getPlayer(game, inningsNo);
  const team = getTeam(game, player);
  const initial = makeInitialBattingPartnerships(team);
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);

  const tally = [0].concat(
    innings.map((_over, i) => {
      const inningsToThisPoint = innings.slice(0, i + 1);
      const partnerships = ballByBall(initial, inningsToThisPoint, target);
      const score = calculateScore(partnerships);

      return score;
    })
  );

  return tally;
};

/**
 * @param {Game} game
 * @param {number} inningsNo
 * @param {number} threshold
 * @param {number} offset
 */
const calculateWicketsForEachOver = (game, inningsNo, threshold, offset) => {
  const player = getPlayer(game, inningsNo);
  const team = getTeam(game, player);
  const initial = makeInitialBattingPartnerships(team);
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);

  const tally = innings.map((_over, i) => {
    const inningsToThisPoint = i === 0 ? [] : innings.slice(0, i);
    const inningsNow = innings.slice(0, i + 1);

    const partnershipsBefore = ballByBall(initial, inningsToThisPoint, target);
    const partnershipsAt = ballByBall(initial, inningsNow, target);
    const wicketsBefore = calculateWickets(partnershipsBefore);
    const scoreNow = calculateScore(partnershipsAt);
    const wicketsNow = calculateWickets(partnershipsAt);

    const wicketsInOver = wicketsNow - wicketsBefore;

    return wicketsInOver <= threshold ? null : scoreNow - offset;
  });
  return tally;
};

/**
 * @param {Game} game
 */
export function buildChartDataV2(game) {
  const p1OversWithWickets = calculateWicketsForEachOver(game, 1, 0, 0);
  const p1OversWithWickets2 = calculateWicketsForEachOver(game, 1, 1, 20);
  const p1OversWithWickets3 = calculateWicketsForEachOver(game, 1, 2, 40);
  const p2OversWithWickets = calculateWicketsForEachOver(game, 2, 0, 0);
  const p2OversWithWickets2 = calculateWicketsForEachOver(game, 2, 1, 20);
  const p2OversWithWickets3 = calculateWicketsForEachOver(game, 2, 2, 40);

  const innings1Colour = getBatterForInnings(game, 1) === "1" ? p1Colour : p2Colour;
  const innings2Colour = getBatterForInnings(game, 2) === "1" ? p1Colour : p2Colour;

  const data = {
    datasets: [
      {
        type: "scatter",
        borderColor: innings1Colour,
        backgroundColor: innings1Colour,
        // @ts-ignore
        data: [null].concat(p1OversWithWickets),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: innings1Colour,
        backgroundColor: innings1Colour,
        // @ts-ignore
        data: [null].concat(p1OversWithWickets2),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: innings1Colour,
        backgroundColor: innings1Colour,
        // @ts-ignore
        data: [null].concat(p1OversWithWickets3),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: innings2Colour,
        backgroundColor: innings2Colour,
        // @ts-ignore
        data: [null].concat(p2OversWithWickets),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: innings2Colour,
        backgroundColor: innings2Colour,
        // @ts-ignore
        data: [null].concat(p2OversWithWickets2),
        pointRadius: 8,
        label: false,
      },
      {
        type: "scatter",
        borderColor: innings2Colour,
        backgroundColor: innings2Colour,
        // @ts-ignore
        data: [null].concat(p2OversWithWickets3),
        pointRadius: 8,
        label: false,
      },
      {
        pointStyle: false,
        type: "line",
        borderColor: innings1Colour,
        backgroundColor: innings1Colour,
        data: calculateRunsForEachOver(game, 1),
        label: getBatterForInnings(game, 1) ? "Player 1" : "Player 2",
      },
      {
        pointStyle: false,
        type: "line",
        borderColor: innings2Colour,
        backgroundColor: innings2Colour,
        data: calculateRunsForEachOver(game, 2),
        label: getBatterForInnings(game, 2) ? "Player 1" : "Player 2",
      },
    ],
    labels: [0].concat(
      Array(20)
        .fill(0)
        .map((_, i) => i + 1)
    ),
  };
  return data;
}
