import React from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import logo from "../assets/logo.png";
import { CenterOnPage } from "../layout/CenterOnPage";
import { useSearchParams } from "react-router-dom";
import { useStepFromRoute } from "../util/route-queries";

export const HowToPlay = () => {
  const step = useStepFromRoute();
  const [, setSearchParams] = useSearchParams();

  if (step === "") {
    setSearchParams({ step: "1" });
  }

  return (
    <CenterOnPage>
      <Box p={1}>
        <Paper>
          <Stack direction="row" justifyContent="center" display="flex">
            <img src={logo} alt="" style={{ width: "25%", maxWidth: 400, height: "auto" }} />
          </Stack>

          <Box p={1}>
            <Typography textAlign="center" variant="h3">
              HOW TO PLAY
            </Typography>
          </Box>
          <Box p={1} display="flex" justifyContent="center">
            <Typography textAlign="center" maxWidth="70ch">
              In Dice Cricket World Cup you play as a team trying to win a game of 20-over cricket. Each over you'll
              choose which dice to roll in the hope of scoring runs, countering your opponent or getting your opponent
              out. Each team will play an innings of up to 20 overs and the team with the highest score wins!
            </Typography>
          </Box>
        </Paper>
      </Box>
    </CenterOnPage>
  );
};
