import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createGame } from "../services/games";
import { s2Token } from "../util/type-conversion";
import { useNavigate } from "react-router-dom";

/**
 * @param {object} props
 * @param {Config} props.config
 */
export const StartNewGame = ({ config }) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      return await createGame(token, config);
    },
    onSuccess: (gameId) => {
      navigate(`/games/${gameId}`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const startNewGameHandler = () => {
    mutation.mutate();
  };

  return (
    <Button variant="contained" onClick={startNewGameHandler}>
      Start New Game
    </Button>
  );
};
