import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import App from "./App";
import { Profile } from "./Profile";
import reportWebVitals from "./reportWebVitals";
import { registerChartJs } from "./register-chart-js";
import { LogoutScreen } from "./auth/Logout";
import { ProtectedRoute } from "./auth/Login";
import { HomePage } from "./auth/HomePage";
import { Rules } from "./rule/Rules";
import { HowToPlay } from "./rule/HowToPlay";
import { RedirectToLastGame, SaveGameId } from "./RedirectToLastGame";
import { RedirectIfNoProfile } from "./RedirectIfNoProfile";

registerChartJs();

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RedirectIfNoProfile>
        <RedirectToLastGame>
          <ProtectedRoute Screen={HomePage} />
        </RedirectToLastGame>
      </RedirectIfNoProfile>
    ),
  },
  {
    path: "/new-game",
    element: <ProtectedRoute Screen={HomePage} />,
  },
  {
    path: "/rules",
    element: <Rules />,
  },
  {
    path: "/how-to-play",
    element: <HowToPlay />,
  },
  {
    path: "/games/:gameId",
    element: (
      <SaveGameId>
        <ProtectedRoute Screen={App} />
      </SaveGameId>
    ),
  },
  {
    path: "/me",
    element: <ProtectedRoute Screen={Profile} />,
  },
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
]);

const Thing = () => {
  return (
    <React.StrictMode>
      <Auth0Provider
        domain="seabrook-studios.au.auth0.com"
        clientId="WtZVQ7wTmu2ysuh88ryJ9QlEKGPCgmRy"
        authorizationParams={{
          audience: "https://secure.dicecricketworldcup.com",
          redirect_uri: window.location.origin,
          cacheLocation: "localstorage",
          useRefreshTokens: true,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </Auth0Provider>
    </React.StrictMode>
  );
};

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Thing />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
