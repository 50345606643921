import React from "react";
import { Backdrop, Box, Card, Grid, Stack, Typography, Zoom } from "@mui/material";
import { Chances, Dot, dice } from "../models/dice";
import { colors } from "./colors";
import { calculateOverScore, calculateScore, calculateWickets, getBattingTeam, getTarget } from "../models/game";
import { ballByBall, getRunsFromFace } from "../models/ball-by-ball";
import { makeInitialBattingPartnerships } from "../models/partnerships";
import { CenterOnPage } from "../layout/CenterOnPage";

/**
 * @typedef {object} BallDisplay
 * @property {import("../types/type").DieId} id
 * @property {DiceFaces} face
 * @property {boolean} isWicket
 * @property {number} runsFromBall
 * @property {number} scoreAtEndOfBall
 * @property {number} wicketsAtEndOfBall
 * @property {boolean} skip
 */

const DieFace = ({ value, text, color }) => {
  const size = "8vw";

  return (
    <Box p={0.5} pl={0.25} pr={0.25}>
      <Box
        p={1}
        boxShadow={1}
        style={{ height: size, width: size, backgroundColor: color }}
        borderRadius={1}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Typography style={{ fontSize: "4vw", color: text }}>{value}</Typography>
      </Box>
    </Box>
  );
};

const DieFaceGap = () => {
  const size = "8vw";

  return (
    <Box p={0.5} pl={0.25} pr={0.25}>
      <Box
        p={1}
        style={{ height: size, width: size, backgroundColor: "transparent" }}
        borderRadius={1}
        justifyContent="center"
        alignItems="center"
        display="flex"
      ></Box>
    </Box>
  );
};

const DieFaceCancelled = ({ value, text, color }) => {
  const size = "5vw";

  return (
    <Box p={0.5} pl={0.25} pr={0.25}>
      <Box
        p={1}
        boxShadow={1}
        style={{ height: size, width: size, backgroundColor: color }}
        borderRadius={1}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Typography style={{ fontSize: "4vw", color: text }}>{value}</Typography>
      </Box>
    </Box>
  );
};

const pacing = 500;
const revealScore = pacing * 5 + pacing / 2;
const cancelledDice = pacing * 6;

/**
 * @param {object} props
 * @param {BallDisplay[]} props.diceRolls
 * @param {number} props.score
 * @param {number} props.wickets
 * @param {number} props.target
 */
const RollDisplay = ({ diceRolls, wickets, score, target }) => {
  const allOut = wickets === 10 || score >= target;
  const dotsToAdd = 6 - diceRolls.length;

  return (
    <Grid container>
      {diceRolls
        .filter((roll) => !roll.skip)
        .map((roll, i) => {
          const die = dice.find((die) => die.id === roll.id);
          if (!die) {
            return null;
          }

          const display = roll.isWicket ? "OUT!" : roll.face;

          return (
            <Grid item xs={2} key={`${roll.id}-${i}`}>
              <Zoom in style={{ transitionDelay: `${i * pacing}ms` }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <DieFace value={display} text={colors[die.type].text} color={colors[die.type].color} />
                </Box>
              </Zoom>
            </Grid>
          );
        })}
      {diceRolls
        .filter((roll) => roll.skip)
        .map((roll, i) => {
          const die = dice.find((die) => die.id === roll.id);
          if (!die) {
            return null;
          }

          return (
            <Grid item xs={2} key={`${roll.id}-${i}`}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <DieFaceGap />
              </Box>
            </Grid>
          );
        })}
      {dotsToAdd > 0 &&
        Array(dotsToAdd)
          .fill(0)
          .map((_, i) => diceRolls.length + i)
          .map((i) => {
            return (
              <Grid item xs={2} key={i}>
                <Zoom in style={{ transitionDelay: `${i * pacing}ms` }}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    {allOut ? (
                      <DieFaceGap />
                    ) : (
                      <DieFace value={Dot} text={colors.white.text} color={colors.white.color} />
                    )}
                  </Box>
                </Zoom>
              </Grid>
            );
          })}
      {diceRolls
        .filter((roll) => !roll.skip)
        .map((roll, i) => {
          const die = dice.find((die) => die.id === roll.id);
          if (!die) {
            return null;
          }

          return (
            <Grid item xs={2} key={`${roll.id}-${i}-score`}>
              <Zoom in style={{ transitionDelay: `${i * pacing}ms` }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography>
                    {roll.wicketsAtEndOfBall}/{roll.scoreAtEndOfBall}
                  </Typography>
                </Box>
              </Zoom>
            </Grid>
          );
        })}
      {diceRolls
        .filter((roll) => roll.skip)
        .map((roll, i) => {
          const die = dice.find((die) => die.id === roll.id);
          if (!die) {
            return null;
          }

          return (
            <Grid item xs={2} key={`${roll.id}-${i}-score`}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography></Typography>
              </Box>
            </Grid>
          );
        })}
      {dotsToAdd > 0 &&
        Array(dotsToAdd)
          .fill(0)
          .map((_, i) => diceRolls.length + i)
          .map((i) => {
            return (
              <Grid item xs={2} key={i}>
                <Zoom in style={{ transitionDelay: `${i * pacing}ms` }}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography>{allOut ? "" : `${wickets}/${score}`}</Typography>
                  </Box>
                </Zoom>
              </Grid>
            );
          })}
    </Grid>
  );
};

/**
 * @param {object} props
 * @param {DiceRoll[]} props.diceRolls
 */
const CancelledBallsDisplay = ({ diceRolls }) => {
  return (
    <Grid container display="flex" justifyContent="center" style={{ opacity: 0.3 }}>
      {diceRolls.map((roll, i) => {
        const die = dice.find((die) => die.id === roll.id);
        if (!die) {
          return null;
        }

        return (
          <Grid item xs={2} key={`${roll.id}-${i}`}>
            <Zoom in style={{ transitionDelay: `${cancelledDice}ms` }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <DieFaceCancelled value={roll.face} text={colors[die.type].text} color={colors[die.type].color} />
              </Box>
            </Zoom>
          </Grid>
        );
      })}
    </Grid>
  );
};

/**
 * @param {Partial<Over>} over
 * @returns {Over}
 */
const makeOver = (over) => ({
  batterRollsv2: [],
  bowlerAtackRollsv2: [],
  batterRolls: [],
  bowlerRolls: [],
  bowlerRollsv2: [],
  chances: 0,
  number: 0,
  runs: 0,
  ...over,
});

/**
 * @type {DiceRoll[]}
 */
const initialBallsInOver = [];

/**
 * @type {BallDisplay[]}
 */
const initialDisplay = [];

/**
 * @param {object} props
 * @param {Game} props.game
 * @param {Function} props.closeDisplay
 */
export const OverAnimation = ({ game, closeDisplay }) => {
  const team = getBattingTeam(game);
  const innings = game.innings === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);
  const inningsToPriorOver = game.over === 1 ? [] : innings.slice(0, innings.length - 1);
  let ballsInOver = [];

  /**
   * @param {DiceRoll[]} ballsUsed
   */
  const getBallsUsed = (ballsUsed) => {
    ballsInOver = ballsUsed;
  };

  const toEndOfOver = ballByBall(makeInitialBattingPartnerships(team), innings, target, getBallsUsed);

  const over = innings[innings.length - 1];
  if (!over) {
    return null;
  }

  const cancelledBalls = [
    ...over.bowlerAtackRollsv2.filter((roll) => roll.cancelled),
    ...over.batterRollsv2.filter((roll) => roll.cancelled),
    ...over.bowlerRollsv2.filter((roll) => roll.cancelled),
  ];

  const display = ballsInOver.reduce(
    ({ overSoFar, displaySoFar }, ball) => {
      /**
       * @type {Over}
       */

      const overBeforeBall = makeOver({
        batterRollsv2: overSoFar.length < 1 ? [] : overSoFar.slice(1),
        bowlerAtackRollsv2: overSoFar.length === 0 ? [] : [overSoFar[0]],
      });

      /**
       * @type {Over}
       */
      const over = makeOver({
        batterRollsv2: overSoFar.length < 1 ? [] : overSoFar.slice(1).concat(ball),
        bowlerAtackRollsv2: overSoFar.length === 0 ? [ball] : [overSoFar[0]],
      });

      const toBallBefore = ballByBall(makeInitialBattingPartnerships(team), [...inningsToPriorOver, overBeforeBall]);
      const toBall = ballByBall(makeInitialBattingPartnerships(team), [...inningsToPriorOver, over]);
      const wicketsBeforeBall = calculateWickets(toBallBefore);
      const wicketsAfterBall = calculateWickets(toBall);
      const runsAfterBall = calculateScore(toBallBefore) + getRunsFromFace(ball.face);

      const isWicket = wicketsAfterBall > wicketsBeforeBall;
      const reachedTarget = !!target ? calculateScore(toBallBefore) >= target : false;

      /**
       * @type {BallDisplay}
       */
      const ballDisplay = {
        id: ball.id,
        face: ball.face,
        isWicket,
        runsFromBall: isWicket ? 0 : getRunsFromFace(ball.face),
        scoreAtEndOfBall: runsAfterBall,
        wicketsAtEndOfBall: wicketsAfterBall,
        skip: wicketsBeforeBall === 10 || reachedTarget,
      };

      const postBallDisplay = Chances.includes(ball.face) && !isWicket ? displaySoFar : [...displaySoFar, ballDisplay];

      return {
        overSoFar: [...overSoFar, ball],
        displaySoFar: postBallDisplay,
      };
    },
    { overSoFar: initialBallsInOver, displaySoFar: initialDisplay }
  );

  return (
    <Backdrop open onClick={closeDisplay} style={{ zIndex: 100, backgroundColor: "#222222DD" }}>
      <CenterOnPage>
        <Stack direction="row" display="flex" flex="1">
          <Box display="flex" flex={1}>
            <Card style={{ display: "flex", flex: 1 }}>
              <Box p={4}>
                <Stack direction="column" display="flex" flex={1}>
                  <Grid item xs={12}>
                    <Typography textAlign="center">Over #{over.number}</Typography>
                  </Grid>
                  <RollDisplay
                    diceRolls={display.displaySoFar.slice(0, 6)}
                    wickets={calculateWickets(toEndOfOver)}
                    score={calculateScore(toEndOfOver)}
                    target={getTarget(game)}
                  />
                  <Grid item xs={12} pt={1}>
                    <Zoom in style={{ transitionDelay: `${revealScore}ms` }}>
                      <Typography textAlign="center">
                        {calculateOverScore(team, innings, innings.length - 1)}
                      </Typography>
                    </Zoom>
                  </Grid>
                  <Grid item xs={12} pt={1}>
                    <Zoom in style={{ transitionDelay: `${cancelledDice}ms` }}>
                      <Typography textAlign="center">
                        {cancelledBalls.length === 0 ? "No cancelled dice" : "Cancelled Dice"}
                      </Typography>
                    </Zoom>
                  </Grid>
                  <CancelledBallsDisplay diceRolls={cancelledBalls} />
                </Stack>
              </Box>
            </Card>
          </Box>
        </Stack>
      </CenterOnPage>
    </Backdrop>
  );
};
