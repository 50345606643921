export const options = {
  animation: true,
  layout: { padding: 8 },
  plugins: {
    legend: { display: false },
  },
  maintainAspectRatio: false,
  responsive: true,
  xAxes: [
    {
      ticks: {},
      gridLines: {
        display: false,
        drawBorder: false,
      },
    },
  ],
  yAxes: [
    {
      ticks: {
        beginAtZero: true,
        min: 0,
      },
      gridLines: {
        borderDash: [2],
        borderDashOffset: [2],
        drawBorder: false,
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
      },
    },
  ],
  scales: {
    x: {
      max: 20,
    },
    y: {},
  },
};

export const runsBreakdownOptions = {
  animation: true,
  layout: { padding: 8 },
  plugins: {
    legend: { display: false },
  },
  maintainAspectRatio: false,
  responsive: true,
  xAxes: [
    {
      ticks: {},
      gridLines: {
        display: false,
        drawBorder: false,
      },
    },
  ],
  yAxes: [
    {
      ticks: {
        beginAtZero: true,
        min: 0,
      },
      gridLines: {
        borderDash: [2],
        borderDashOffset: [2],
        drawBorder: false,
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
      },
    },
  ],
  indexAxis: "y",
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export const chancesOptions = {
  animation: true,
  layout: { padding: 8 },
  plugins: {
    legend: { display: false },
  },
  maintainAspectRatio: false,
  responsive: true,
  xAxes: [
    {
      ticks: {},
      gridLines: {
        display: false,
        drawBorder: false,
      },
    },
  ],
  yAxes: [
    {
      ticks: {
        beginAtZero: true,
        min: 0,
      },
      gridLines: {
        borderDash: [2],
        borderDashOffset: [2],
        drawBorder: false,
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
      },
    },
  ],
  indexAxis: "y",
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

/**
 * @param {number} minMax
 * @returns {import("chart.js").CoreChartOptions<"bar">}
 */
export const partnershipChartOptions2 = (minMax) => ({
  animation: true,
  layout: { padding: 0 },
  plugins: {
    legend: { display: false },
  },
  maintainAspectRatio: false,
  responsive: true,
  xAxes: [
    {
      ticks: {
        min: -150,
      },
      gridLines: {
        display: false,
        drawBorder: false,
      },
    },
  ],
  yAxes: [
    {
      ticks: {
        beginAtZero: true,
        min: 0,
      },
      gridLines: {
        display: false,
      },
    },
  ],
  indexAxis: "y",
  scales: {
    x: {
      stacked: true,
      display: false,
      ticks: {
        callback: (val) => {
          return val < 0 ? val * -1 : val;
        },
      },
      min: -minMax,
      max: minMax,
    },
    y: {
      stacked: true,
      display: false,
    },
  },
});
