/**
 * @param {number} balls
 * @returns {string}
 */
export const formatBalls = (balls) => {
  if (Math.floor(balls / 6) === 20) {
    return "20";
  }

  return `${Math.floor(balls / 6)}.${Math.floor(balls % 6)}`;
};
