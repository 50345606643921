import React from "react";
import { Box, Button, Card, Stack } from "@mui/material";
import { Line } from "react-chartjs-2";
import { options } from "./options";
import { buildChartData, buildChartDataV2 } from "./buildChartData";
import { useStartPlay } from "../queries/games";
import { useGameIdFromRoute, usePlayerFromRoute } from "../util/route-queries";
import { BowlerSummary, BowlerSummaryV2 } from "./BowlerSummary";
import { InningsSummaryV2 } from "./InningsSummary";
import { BattingSummary } from "./BattingSummary";
import { Partnerships } from "./Partnerships";

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const InningsComplete = ({ game }) => {
  const gameId = useGameIdFromRoute();
  const player = usePlayerFromRoute();
  const startPlay = useStartPlay(gameId);

  const data = buildChartData(game.firstInnings, []);
  const dataV2 = buildChartDataV2(game);

  const onStartNextInningsHandler = () => {
    startPlay.mutate();
  };

  return (
    <Box display="flex" m={0} p={1} flex={1}>
      <Stack direction="column" display="flex" flex="1">
        <Box>
          {game.config.useIndividualBallTracking ? (
            <InningsSummaryV2 game={game} title={game.battingPlayer === "1" ? "PLAYER 1" : "PLAYER 2"} inningsNo={1} />
          ) : (
            <BattingSummary game={game} />
          )}
        </Box>

        <Box pt={1}>
          {game.config.useIndividualBallTracking ? (
            <BowlerSummaryV2 game={game} inningsNo={1} />
          ) : (
            <BowlerSummary game={game} inningsNo={game.innings} />
          )}
        </Box>

        <Box pt={1}>
          <Partnerships game={game} inningsNo={game.innings} />
        </Box>

        <Box pt={1}>
          <Card>
            <Box sx={{ mt: 3, height: 300 }}>
              <Line
                // @ts-ignore
                data={game.config.useIndividualBallTracking ? dataV2 : data}
                // @ts-ignore
                options={options}
              />
            </Box>
          </Card>
        </Box>

        <Box pt={1}>
          <Card>
            <Box p={1} display="flex" justifyContent="center">
              <Button onClick={onStartNextInningsHandler} variant="contained" disabled={player === "2"}>
                START NEXT INNINGS
              </Button>
            </Box>
          </Card>
        </Box>
      </Stack>
    </Box>
  );
};
