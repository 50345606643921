import React from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { dice } from "../models/dice";
import { Die, DieDisplay } from "./Die";
import { calculateBonusChances, makeInitialBattingPartnerships } from "../models/partnerships";
import { p1Colour, p2Colour } from "./colors";
import { calculateScore, findScoreForPlayer, getBattingTeam, getPlayer, getTarget } from "../models/game";
import { ballByBall, getCurrentPartnership } from "../models/ball-by-ball";
import duck from "../assets/duck.png";
import { RunsPerBonusChance } from "../models/chances";

export const Scorecard = ({ partnerships, selectionsBatter, game, wickets }) => {
  const inningsColour = game.battingPlayer === "2" && game.innings === 2 ? p1Colour : p2Colour;

  return (
    <Card style={{ display: "flex" }}>
      <Stack direction="column" display="flex" flex="1">
        <Grid container columns={12}>
          <Grid item xs={5} p={1} style={{ backgroundColor: inningsColour, color: "white" }}>
            <Typography variant="body2" pl={0.5}>
              PLAYER
            </Typography>
          </Grid>
          <Grid item xs={4.5} p={1} style={{ backgroundColor: inningsColour, color: "white" }}>
            <Typography variant="body2">CHANCES</Typography>
          </Grid>
          <Grid item xs={1} p={1} style={{ backgroundColor: inningsColour, color: "white" }}>
            <Typography variant="body2" textAlign="center">
              DICE
            </Typography>
          </Grid>
          <Grid item xs={1.5} p={1} style={{ backgroundColor: inningsColour, color: "white" }}>
            <Typography variant="body2" textAlign="right" pr={0.5}>
              RUNS
            </Typography>
          </Grid>

          {partnerships.map(({ id, chances, maxDice, runs, out, chancesLost }, i) => {
            const backgroundColor = wickets + 1 === id ? (game.battingPlayer === "1" ? p1Colour : p2Colour) : "";
            const diceId = selectionsBatter[i];
            const die = dice.find(({ id }) => id === diceId);
            if (!die) {
              return null;
            }

            return (
              <>
                <Grid item xs={5} style={{ backgroundColor }}>
                  <Die verysmall noIcon id={die.id} selectDie={() => null} faces={die.faces} type={die.type} />
                </Grid>
                <Grid
                  item
                  xs={4.5}
                  style={{ backgroundColor }}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Stack direction="row" display="flex" justifyContent="flex-start" alignItems="center">
                    {Array(chances + calculateBonusChances(runs))
                      .fill(0)
                      .map((_, i) => `${id}-${i}`)
                      .map((chanceId, i) => {
                        const filled = out || chancesLost > i;

                        return (
                          <Box key={chanceId} pl={0.25} pr={0.25}>
                            <Box
                              p={1}
                              style={{
                                height: 4,
                                width: 4,
                                borderStyle: filled ? "solid" : "dotted",
                                backgroundColor: filled ? "#999" : "#fff",
                              }}
                              borderColor="black"
                            />
                          </Box>
                        );
                      })}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ backgroundColor }}
                >
                  <Typography color={wickets + 1 === id ? "white" : "black"} textAlign="center">
                    {maxDice}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ backgroundColor }}
                >
                  <Typography color={wickets + 1 === id ? "white" : "black"} textAlign="right" pr={0.5}>{`${runs}${
                    wickets + 1 === id ? "*" : ""
                  }`}</Typography>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Stack>
    </Card>
  );
};

export const ScorecardV2 = ({ game }) => {
  const player = getPlayer(game, game.innings);
  const team = player === "1" ? game.player1Selections : game.player2Selections;
  const initial = makeInitialBattingPartnerships(team);
  const innings = game.innings === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);
  const partnerships = ballByBall(initial, innings, target);

  const inningsColour = player === "1" ? p1Colour : p2Colour;

  return (
    <Card style={{ display: "flex" }}>
      <Stack direction="column" display="flex" flex="1">
        <Grid container columns={12}>
          <Grid item xs={1} p={1} style={{ backgroundColor: inningsColour, color: "white" }}>
            <Typography variant="body2" textAlign="center" pl={0.5}>
              #
            </Typography>
          </Grid>
          <Grid item xs={6} p={1} style={{ backgroundColor: inningsColour, color: "white" }}>
            <Typography variant="body2" pl={0.5}>
              BATTER
            </Typography>
          </Grid>
          <Grid item xs={5} p={1} style={{ backgroundColor: inningsColour, color: "white" }}>
            <Typography variant="body2" textAlign="right">
              RUNS
            </Typography>
          </Grid>
          {team.map((id, i) => {
            const score = findScoreForPlayer(partnerships, id);
            const { out } = score;
            const isNotOutBatter = !score.out;
            const DNB = !out && !isNotOutBatter;
            const playerColour = game.battingPlayer === "1" ? p1Colour : p2Colour;
            const backgroundColor = score.out || score.balls === 0 ? "#ffffff" : playerColour;
            const isDuck = out && score.runs === 0;

            const die = dice.find((die) => die.id === id);
            if (!die) {
              return null;
            }

            return (
              <React.Fragment key={id}>
                <Grid
                  item
                  xs={1}
                  style={{ backgroundColor: `${backgroundColor}33` }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="body2" pl={0.5}>
                    {i + 1}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ backgroundColor: `${backgroundColor}33` }}>
                  <DieDisplay verysmall id={die.id} faces={die.faces} type={die.type} />
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ backgroundColor: `${backgroundColor}33` }}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {score.balls > 0 && (
                    <Box flex={1} pr={1}>
                      {isDuck ? (
                        <Typography textAlign="right">
                          <img src={duck} alt="duck" style={{ width: 16, height: 16 }} /> ({score.balls})
                        </Typography>
                      ) : (
                        <Typography textAlign="right">{`${DNB ? "" : score.runs}${!out && isNotOutBatter ? "*" : ""} (${
                          score.balls
                        })`}</Typography>
                      )}
                    </Box>
                  )}
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Stack>
    </Card>
  );
};

export const CurrentPartnership = ({ partnerships, selectionsBatter, game, wickets }) => {
  return (
    <Card style={{ display: "flex" }}>
      <Stack direction="column" display="flex" flex="1">
        {partnerships
          .filter((p) => wickets + 1 === p.id)
          .map(({ id, chances, runs, out, chancesLost }, i) => {
            const diceId = selectionsBatter[i];
            const die = dice.find(({ id }) => id === diceId);
            if (!die) {
              return null;
            }

            return (
              <Stack
                direction="row"
                key={id}
                p={0.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  backgroundColor: wickets + 1 === id ? (game.battingPlayer === "1" ? p1Colour : p2Colour) : "",
                }}
              >
                <Box flex={1}>
                  <Die noIcon small id={die.id} selectDie={() => null} faces={die.faces} type={die.type} />
                </Box>
                <Grid flex={3} container>
                  {Array(chances + calculateBonusChances(runs))
                    .fill(0)
                    .map((_, i) => `${id}-${i}`)
                    .map((chanceId, i) => {
                      const filled = out || chancesLost > i;

                      return (
                        <Box key={chanceId} pl={0.5} pr={0.5}>
                          <Box
                            p={1}
                            style={{
                              height: 4,
                              width: 4,
                              borderStyle: filled ? "solid" : "dotted",
                              backgroundColor: filled ? "#999" : "#fff",
                            }}
                            borderColor="black"
                          />
                        </Box>
                      );
                    })}
                </Grid>
                <Box flex={0.5}>
                  <Typography color={wickets + 1 === id ? "white" : "black"} textAlign="right">{`${runs}${
                    wickets + 1 === id ? "*" : ""
                  }`}</Typography>
                </Box>
              </Stack>
            );
          })}
      </Stack>
    </Card>
  );
};

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const CurrentPartnershipV2 = ({ game }) => {
  const team = getBattingTeam(game);
  const initial = makeInitialBattingPartnerships(team);
  const innings = game.innings === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);
  const partnerships = ballByBall(initial, innings, target);

  const inningsColour = game.battingPlayer === "1" ? p1Colour : p2Colour;

  const partnership = getCurrentPartnership(partnerships);
  if (!partnership) {
    return null;
  }

  const nextChance = Math.floor(partnership.runs / RunsPerBonusChance + 1) * RunsPerBonusChance;

  return (
    <Card style={{ display: "flex" }}>
      <Stack direction="column" display="flex" flex="1">
        <Stack
          direction="row"
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{
            backgroundColor: inningsColour,
          }}
        >
          <Grid flex={3} container>
            {Array(partnership.chances + calculateBonusChances(partnership.runs))
              .fill(0)
              .map((_, i) => `${partnership.id}-${i}`)
              .map((chanceId, i) => {
                const filled = partnership.chancesLost > i;

                return (
                  <Box key={chanceId} pl={0.5} pr={0.5}>
                    <Box
                      p={1}
                      style={{
                        height: 4,
                        width: 4,
                        borderStyle: filled ? "solid" : "dotted",
                        backgroundColor: filled ? "#999" : "#fff",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderColor="black"
                    >
                      {filled && (
                        <Typography variant="body2" color="white">
                          X
                        </Typography>
                      )}
                    </Box>
                  </Box>
                );
              })}
            <Box pl={0.5} pr={0.5}>
              <Box
                p={1}
                style={{
                  height: 4,
                  width: 4,
                  borderStyle: "dotted",
                  backgroundColor: "#fff",
                }}
                borderColor="black"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">{nextChance}</Typography>
              </Box>
            </Box>
          </Grid>
          <Box flex={1}>
            <Typography color="white" textAlign="right">{`${partnership.runs}* (${partnership.balls})`}</Typography>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

/**
 * @param {BattingPartnership[]} partnerships
 * @param {BattingPartnership} partnership
 * @returns {string}
 */
const calculateFOW = (partnerships, partnership) => {
  const pBefore = partnerships.filter((p) => p.id <= partnership.id);
  const runs = calculateScore(pBefore);

  return partnership.out ? `${pBefore.length}/${runs}` : `#${partnership.id}`;
};

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const CurrentPartnershipV3 = ({ game }) => {
  const team = getBattingTeam(game);
  const initial = makeInitialBattingPartnerships(team);
  const innings = game.innings === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);
  const partnerships = ballByBall(initial, innings, target);

  const inningsColour = game.battingPlayer === "1" ? p1Colour : p2Colour;

  return (
    <Card style={{ display: "flex" }}>
      <Stack direction="column" display="flex" flex="1">
        <Stack
          direction="row"
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{
            backgroundColor: inningsColour,
          }}
        >
          {partnerships.map((p, i) => {
            const totalChances = p.chances + calculateBonusChances(p.runs);
            const remainingChances = totalChances - p.chancesLost;
            const isLast = partnerships.length - 1 === i;

            return (
              <Box key={p.id} flex={1 / 10} style={{ borderRight: isLast ? "" : "1px solid white" }} pl={0.5} pr={0.5}>
                <Stack direction="column">
                  <Typography textAlign="center" variant="body2" color="white" fontSize="small">
                    {calculateFOW(partnerships, p)}
                  </Typography>
                  <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
                    {p.chancesLost > 0 &&
                      Array(p.chancesLost)
                        .fill(0)
                        .map((_, i) => (
                          <Typography variant="body2" key={`${p.id}-${i}`} fontSize="small" color="white">
                            x
                          </Typography>
                        ))}
                    {remainingChances > 0 &&
                      Array(remainingChances)
                        .fill(0)
                        .map((_, i) => (
                          <Typography variant="body2" key={`${p.id}-${i}`} fontSize="small">
                            o
                          </Typography>
                        ))}
                  </Stack>
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Card>
  );
};
