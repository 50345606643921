import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "./queries/user";
import { CenterOnPage } from "./layout/CenterOnPage";
import { CircularProgress } from "@mui/material";

export const RedirectIfNoProfile = ({ children }) => {
  const navigate = useNavigate();
  const { data: profile, isLoading } = useUserProfile();

  useEffect(() => {
    if (!profile) {
      return;
    }

    if (profile.shortName.length === 0) {
      setTimeout(() => {
        navigate(`/me`);
      }, 0);
    }
  }, [profile, navigate]);

  if (isLoading) {
    return (
      <CenterOnPage>
        <CircularProgress />
      </CenterOnPage>
    );
  }
  return children;
};
