import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { s2Token } from "../util/type-conversion";
import { fetchMyProfile, fetchUserProfile, updateUserProfile } from "../services/users";

/**
 * @returns {string[]}
 */
const ThisUser = () => ["this-user"];

export const useUserProfile = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ThisUser(),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      const user = await fetchMyProfile(token);

      return user;
    },
  });
};

export const usePublicProfile = (userId) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ["user", userId],
    queryFn: async () => {
      if (!userId) {
        return null;
      }
      if (userId === "BATTER") {
        return null;
      }
      if (userId === "BOWLER") {
        return null;
      }
      if (userId === "RANDOM") {
        return null;
      }

      const token = s2Token(await getAccessTokenSilently());
      const user = await fetchUserProfile(token, userId);

      return user;
    },
  });
};

export const useUpdateShortName = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    /**
     * @param {string} shortName
     */
    mutationFn: async (shortName) => {
      const token = s2Token(await getAccessTokenSilently());
      await updateUserProfile(token, shortName);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ThisUser() });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
