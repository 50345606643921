const isNumber = require("lodash/isNumber");
const { calculateBonusChances } = require("./partnerships");
const { Dot, X, XXX, XX } = require("./dice");

/**
 * @param {BattingPartnership[]} partnerships
 * @returns {number}
 */
const calculateScore = (partnerships) => partnerships.reduce((t, p) => t + p.runs, 0);

/**
 * @param {BattingPartnership[]} partnerships
 * @returns {BattingPartnership|null}
 */
export const getCurrentPartnership = (partnerships) => {
  const notOutPartnerships = partnerships.filter((p) => !p.out);
  if (notOutPartnerships.length === 0) {
    return null;
  }

  return notOutPartnerships[0];
};

/**
 * @param {BattingPartnership[]} partnerships
 * @param {BattingPartnership} updatedPartnership
 * @returns {BattingPartnership[]}
 */
const replacePartnership = (partnerships, updatedPartnership) => {
  const updatedPartnerships = partnerships.filter((p) => p.id !== updatedPartnership.id).concat(updatedPartnership);

  updatedPartnerships.sort((a, b) => a.id - b.id);

  return updatedPartnerships;
};

/**
 * @param {DiceFaces} face
 */
// @ts-ignore
const isChanceBall = (face) => [XXX, XX, X].includes(face);

/**
 * @param {DiceFaces} face
 * @returns {number} face
 */
// @ts-ignore
export const getRunsFromFace = (face) => (isNumber(face) ? face : 0);

/**
 * @param {BattingPartnership[]} partnerships
 * @param {DiceRoll} ball
 * @returns {BattingPartnership[]}
 */
const processBall = (partnerships, ball) => {
  const currentPartnership = getCurrentPartnership(partnerships);
  if (!currentPartnership) {
    return partnerships;
  }

  if (isChanceBall(ball.face)) {
    const updatedPartnership = {
      ...currentPartnership,
      chancesLost: currentPartnership.chancesLost + 1,
    };

    return replacePartnership(partnerships, updatedPartnership);
  }

  const runs = getRunsFromFace(ball.face);

  const { batter1, batter2 } = currentPartnership;
  if (!batter1 || !batter2) {
    throw new Error("Missing batter");
  }
  const onStrike = currentPartnership.isBatter1OnStrike ? batter1 : batter2;
  const updatedBatter = {
    ...onStrike,
    runs: onStrike.runs + runs,
    balls: onStrike.balls + 1,
  };

  const updatedPartnership = {
    ...currentPartnership,
    runs: currentPartnership.runs + runs,
    runsBatter1: currentPartnership.runsBatter1 + (currentPartnership.isBatter1OnStrike ? runs : 0),
    runsBatter2: currentPartnership.runsBatter2 + (currentPartnership.isBatter1OnStrike ? 0 : runs),
    balls: currentPartnership.balls + 1,
    ballsBatter1: currentPartnership.ballsBatter1 + (currentPartnership.isBatter1OnStrike ? 1 : 0),
    ballsBatter2: currentPartnership.ballsBatter2 + (currentPartnership.isBatter1OnStrike ? 0 : 1),
    batter1: currentPartnership.isBatter1OnStrike ? updatedBatter : batter1,
    batter2: currentPartnership.isBatter1OnStrike ? batter2 : updatedBatter,
    isBatter1OnStrike: [1, 3, 5].includes(runs)
      ? !currentPartnership.isBatter1OnStrike
      : currentPartnership.isBatter1OnStrike,
  };

  return replacePartnership(partnerships, updatedPartnership);
};

/**
 * @param {BattingPartnership[]} inPartnerships
 * @returns {number}
 */
const sumBallsInInnings = (inPartnerships) => {
  return inPartnerships.reduce((t, p) => t + p.balls, 0);
};

/**
 *
 * @param {number} ballsAtStartOfOver
 * @param {BattingPartnership[]} postOverPartnerships
 * @returns
 */
const padOutOver = (ballsAtStartOfOver, postOverPartnerships) => {
  const ballsAtEndOfOver = sumBallsInInnings(postOverPartnerships);
  const padCount = ballsAtStartOfOver + 6 - ballsAtEndOfOver;

  if (padCount <= 0) {
    return postOverPartnerships;
  }

  /**
   * @type {DiceRoll[]}
   */
  const padBalls = Array(padCount).fill({ cancelled: false, face: Dot, id: "padding" });
  const postPad = padBalls
    .filter(({ cancelled }) => !cancelled)
    .reduce((p, ball) => {
      return processBall(p, ball);
    }, postOverPartnerships);

  return postPad;
};

/**
 *
 * @param {BattingPartnership} currentP
 * @param {BattingPartnership[]} inPartnerships
 * @returns {BattingPartnership[]}
 */
const handleWicket = (currentP, inPartnerships) => {
  if (!currentP.batter1 || !currentP.batter2) {
    return inPartnerships;
  }

  /**
   * @type {BattingPartnership}
   */
  const broken = {
    ...currentP,
    out: true,
    balls: currentP.balls + 1,
    ballsBatter1: currentP.ballsBatter1 + (currentP.isBatter1OnStrike ? 1 : 0),
    ballsBatter2: currentP.ballsBatter2 + (currentP.isBatter1OnStrike ? 0 : 1),
    batter1: {
      ...currentP.batter1,
      out: currentP.isBatter1OnStrike,
      balls: currentP.batter1.balls + (currentP.isBatter1OnStrike ? 1 : 0),
    },
    batter2: {
      ...currentP.batter2,
      out: !currentP.isBatter1OnStrike,
      balls: currentP.batter2.balls + (!currentP.isBatter1OnStrike ? 1 : 0),
    },
  };

  const postWicket = replacePartnership(inPartnerships, broken);
  const nextPartnership = getCurrentPartnership(postWicket);
  if (!nextPartnership) {
    return postWicket;
  }

  const newBatter1 = broken.isBatter1OnStrike ? nextPartnership.batter2 : broken.batter1;
  const newBatter2 = broken.isBatter1OnStrike ? broken.batter2 : nextPartnership.batter2;
  /**
   * @type {BattingPartnership}
   */
  const newPartnership = {
    ...nextPartnership,
    batter1: newBatter1,
    batter2: newBatter2,
    isBatter1OnStrike: broken.isBatter1OnStrike,
  };

  return replacePartnership(postWicket, newPartnership);
};

/**
 * @param {BattingPartnership} partnership
 * @return {boolean}
 */
const isWicketLost = (partnership) => {
  const chancesForPair = calculateBonusChances(partnership.runs);
  const wicketThreshold = chancesForPair + partnership.chances;

  return partnership.chancesLost >= wicketThreshold;
};

/**
 * @param {BattingPartnership[]} partnerships
 */
const allOut = (partnerships) => {
  return partnerships.filter((p) => p.out).length === 10;
};

/**
 * @param {Over} over
 * @returns {DiceRoll[]}
 */
export const getBallsForOver = (over) => {
  if (!over) {
    return [];
  }

  const combinedBalls = [...over.bowlerAtackRollsv2, ...over.batterRollsv2, ...over.bowlerRollsv2]
    .map((diceRoll, i) => ({
      ...diceRoll,
      order: i * 10,
    }))
    .map((diceRoll) => {
      if (diceRoll.face === XX) {
        return [
          { ...diceRoll, face: X, order: diceRoll.order },
          { ...diceRoll, face: X, order: diceRoll.order + 5 },
        ];
      }
      if (diceRoll.face === XXX) {
        return [
          { ...diceRoll, face: X, order: diceRoll.order },
          { ...diceRoll, face: X, order: diceRoll.order + 3 },
          { ...diceRoll, face: X, order: diceRoll.order + 6 },
        ];
      }

      return [diceRoll];
    })
    .reduce((all, arr) => [...arr, ...all], []);

  const inOrder = combinedBalls
    .sort((a, b) => a.order - b.order)
    .map((withOrder) => ({
      face: withOrder.face,
      id: withOrder.id,
      cancelled: withOrder.cancelled,
    }))
    .filter(({ cancelled }) => !cancelled);

  return inOrder;
};

/**
 * @param {DiceRoll[]} balls
 * @param {DiceFaces} face
 * @return {DiceRoll[]}
 */
const cancelFirstMatch = (balls, face) => {
  const index = balls.findIndex((ball) => ball.face === face && !ball.cancelled);
  if (index === -1) {
    return balls;
  }

  const toCancel = balls[index];

  return balls.filter((_x, i) => i !== index).concat({ ...toCancel, cancelled: true });
};

/**
 * @param {DiceRoll[]} balls
 * @param {DiceFaces} match
 * @returns {boolean}
 */
const contains = (balls, match) => {
  return balls
    .filter(({ cancelled }) => !cancelled)
    .map(({ face }) => face)
    .includes(match);
};

/**
 * @param {DiceRoll[]} balls
 * @param {DiceFaces} match
 * @returns {DiceRoll[]}
 */
const removeOneBall = (balls, match) => {
  if (contains(balls, match)) {
    return cancelFirstMatch(balls, match).filter(({ cancelled }) => !cancelled);
  }

  return balls;
};

/**
 * @param {DiceRoll[]} balls
 * @returns {DiceRoll[]}
 */
const removeLowest = (balls) => {
  if (contains(balls, 1)) {
    return removeOneBall(balls, 1);
  }

  if (contains(balls, 2)) {
    return removeOneBall(balls, 2);
  }

  if (contains(balls, 3)) {
    return removeOneBall(balls, 3);
  }

  if (contains(balls, 4)) {
    return removeOneBall(balls, 4);
  }

  if (contains(balls, 5)) {
    return removeOneBall(balls, 5);
  }

  if (contains(balls, 6)) {
    return removeOneBall(balls, 6);
  }

  if (contains(balls, Dot)) {
    return removeOneBall(balls, Dot);
  }

  if (contains(balls, X)) {
    return removeOneBall(balls, X);
  }

  if (contains(balls, XX)) {
    return removeOneBall(balls, XX);
  }

  if (contains(balls, XXX)) {
    return removeOneBall(balls, XXX);
  }

  return balls;
};

/**
 * @param {BattingPartnership[]} inPartnerships
 * @param {DiceRoll[]} balls
 * @param {number} [target]
 * @param {Function} [onComplete]
 * @returns {BattingPartnership[]}
 */
const processOver = (inPartnerships, balls, target, onComplete) => {
  if (allOut(inPartnerships)) {
    if (onComplete) {
      onComplete(balls);
    }
    return inPartnerships;
  }

  if (target) {
    if (calculateScore(inPartnerships) >= target) {
      if (onComplete) {
        onComplete(balls);
      }
      return inPartnerships;
    }
  }

  const ballCountAtOverStart = sumBallsInInnings(inPartnerships);

  const outPartnerships = balls.reduce((p, ball) => {
    if (allOut(p)) {
      return p;
    }

    if (target) {
      if (calculateScore(p) >= target) {
        return p;
      }
    }

    const postBall = processBall(p, ball);
    const currentPartnership = getCurrentPartnership(postBall);
    if (!currentPartnership) {
      return postBall;
    }

    if (!isWicketLost(currentPartnership)) {
      return postBall;
    }

    return handleWicket(currentPartnership, postBall);
  }, inPartnerships);

  const ballsBowledSoFar = sumBallsInInnings(outPartnerships);
  const ballsCounted = ballsBowledSoFar - ballCountAtOverStart;
  if (ballsCounted > 6) {
    return processOver(inPartnerships, removeLowest(balls), target, onComplete);
  }

  if (allOut(outPartnerships)) {
    if (onComplete) {
      onComplete(balls);
    }
    return outPartnerships;
  }
  if (target) {
    if (calculateScore(outPartnerships) >= target) {
      if (onComplete) {
        onComplete(balls);
      }
      return outPartnerships;
    }
  }

  const postPad = padOutOver(ballCountAtOverStart, outPartnerships);

  const currentPartnership = getCurrentPartnership(postPad);
  if (!currentPartnership) {
    if (onComplete) {
      onComplete(balls);
    }
    return postPad;
  }

  const updatedPartnership = {
    ...currentPartnership,
    isBatter1OnStrike: !currentPartnership.isBatter1OnStrike,
  };

  if (onComplete) {
    onComplete(balls);
  }
  return replacePartnership(postPad, updatedPartnership);
};

/**
 * @param {BattingPartnership[]} inPartnerships
 * @param {Over[]} overs
 * @param {number} [target]
 * @param {Function} [onComplete]
 * @returns {BattingPartnership[]}
 */
export const ballByBall = (inPartnerships, overs, target, onComplete) => {
  return overs.filter(Boolean).reduce((p, over) => {
    return processOver(p, getBallsForOver(over), target, onComplete);
  }, inPartnerships);
};
