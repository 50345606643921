import React from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { calculatePartnerships, makeInitialBattingPartnerships } from "../models/partnerships";
import { dice } from "../models/dice";
import { DieDisplay } from "./Die";
import { p1Colour, p2Colour } from "./colors";
import {
  calculateBalls,
  calculateScore,
  calculateWickets,
  getBatterForInnings,
  getPlayer,
  getTarget,
  getTeam,
} from "../models/game";
import { ballByBall } from "../models/ball-by-ball";
import { formatBalls } from "./formatBalls";

const getLeadBowlerForOver = (over) => {
  return over.bowlerAtackRollsv2.length > 0 ? over.bowlerAtackRollsv2[0].id : over.bowlerRollsv2[0].id;
};

export const BowlerSummary = ({ game, inningsNo }) => {
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;

  const player = getPlayer(game, inningsNo);
  const inningsColour = player === "1" ? p2Colour : p1Colour;

  const bowlerLeadDiceUsed = innings.map((over) => getLeadBowlerForOver(over));
  const asHistogram = bowlerLeadDiceUsed.reduce(
    (histogram, dieId) => ({
      ...histogram,
      [dieId]: (histogram[dieId] || 0) + 1,
    }),
    {}
  );

  const wicketsHistogram = innings.reduce((wickets, over) => {
    const bowler = getLeadBowlerForOver(over);
    const inningsToThisPoint = over.number === 1 ? [] : innings.slice(0, over.number - 1);
    const partnershipsBeforeThisPoint = calculatePartnerships(inningsToThisPoint);
    const wicketsBeforeOver = partnershipsBeforeThisPoint.filter(({ out }) => out).length;

    const inningsPostOver = innings.slice(0, over.number);
    const partnershipsAfterThisPoint = calculatePartnerships(inningsPostOver);
    const wicketsAfterOver = partnershipsAfterThisPoint.filter(({ out }) => out).length;

    const wicketsInOver = wicketsAfterOver - wicketsBeforeOver;

    return {
      ...wickets,
      [bowler]: (wickets[bowler] || 0) + wicketsInOver,
    };
  }, {});

  const bowlers = Object.keys(asHistogram);

  const calculateRunsForBowler = (dieId) => {
    const bowlerLeadDiceUsed = innings.filter((over) => {
      const bowlerForOver = getLeadBowlerForOver(over);

      return bowlerForOver === dieId;
    });

    return bowlerLeadDiceUsed.reduce((runs, o) => runs + o.runs, 0);
  };

  const calculateMaidensForBowler = (dieId) => {
    const bowlerLeadDiceUsed = innings
      .filter((over) => {
        const bowlerForOver = getLeadBowlerForOver(over);

        return bowlerForOver === dieId;
      })
      .filter((over) => over.runs === 0);

    return bowlerLeadDiceUsed.length;
  };

  const calculateWicketsForBowler = (dieId) => {
    return wicketsHistogram[dieId];
  };

  return (
    <Card style={{ display: "flex", flex: 1 }}>
      <Stack direction="column" display="flex" flex="1">
        <Stack
          direction="row"
          p={1}
          display="flex"
          justifyContent="space-between"
          style={{ backgroundColor: inningsColour, color: "white" }}
        >
          <Box flex={2}>
            <Typography variant="body2" textAlign="left">
              BOWLER
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body2" textAlign="center">
              O
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body2" textAlign="center">
              M
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body2" textAlign="center">
              R
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body2" textAlign="center">
              W
            </Typography>
          </Box>
        </Stack>
        {bowlers.map((bowlerId, i) => {
          const die = dice.find((die) => die.id === bowlerId);
          if (!die) {
            return null;
          }

          return (
            <Stack
              direction="row"
              key={bowlerId}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}
            >
              <Box flex={2}>
                <DieDisplay id={die.id} faces={die.faces} type={die.type} />
              </Box>
              <Box flex={1}>
                <Typography textAlign="center">{asHistogram[bowlerId]}</Typography>
              </Box>
              <Box flex={1}>
                <Typography textAlign="center">{calculateMaidensForBowler(bowlerId)}</Typography>
              </Box>
              <Box flex={1}>
                <Typography textAlign="center">{calculateRunsForBowler(bowlerId)}</Typography>
              </Box>
              <Box flex={1}>
                <Typography textAlign="center">{calculateWicketsForBowler(bowlerId)}</Typography>
              </Box>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
};

/**
 * @param {Game} game
 * @param {number} inningsNo
 */
const calculateWicketsForEachOver = (game, inningsNo) => {
  const player = getPlayer(game, inningsNo);
  const team = getTeam(game, player);
  const initial = makeInitialBattingPartnerships(team);
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);

  const tally = innings.map((_over, i) => {
    const inningsToThisPoint = i === 0 ? [] : innings.slice(0, i);
    const inningsNow = innings.slice(0, i + 1);

    const partnershipsBefore = ballByBall(initial, inningsToThisPoint, target);
    const partnershipsAt = ballByBall(initial, inningsNow, target);
    const wicketsBefore = calculateWickets(partnershipsBefore);
    const wicketsNow = calculateWickets(partnershipsAt);

    const wicketsInOver = wicketsNow - wicketsBefore;

    return wicketsInOver;
  });
  return tally;
};

/**
 * @param {Game} game
 * @param {number} inningsNo
 */
const calculateRunsForEachOver = (game, inningsNo) => {
  const player = getPlayer(game, inningsNo);
  const team = getTeam(game, player);
  const initial = makeInitialBattingPartnerships(team);
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);

  const tally = innings.map((_over, i) => {
    const inningsToThisPoint = i === 0 ? [] : innings.slice(0, i);
    const inningsNow = innings.slice(0, i + 1);

    const partnershipsBefore = ballByBall(initial, inningsToThisPoint, target);
    const partnershipsAt = ballByBall(initial, inningsNow, target);
    const runsBefore = calculateScore(partnershipsBefore);
    const runsNow = calculateScore(partnershipsAt);

    const runsInOver = runsNow - runsBefore;

    return runsInOver;
  });
  return tally;
};

/**
 * @param {Game} game
 * @param {number} inningsNo
 */
const calculateBallsForEachOver = (game, inningsNo) => {
  const player = getPlayer(game, inningsNo);
  const team = getTeam(game, player);
  const initial = makeInitialBattingPartnerships(team);
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);

  const tally = innings.map((_over, i) => {
    const inningsToThisPoint = i === 0 ? [] : innings.slice(0, i);
    const inningsNow = innings.slice(0, i + 1);

    const partnershipsBefore = ballByBall(initial, inningsToThisPoint, target);
    const partnershipsAt = ballByBall(initial, inningsNow, target);
    const ballsBefore = calculateBalls(partnershipsBefore);
    const ballsNow = calculateBalls(partnershipsAt);

    const ballsInOver = ballsNow - ballsBefore;

    return ballsInOver;
  });
  return tally;
};

/**
 * @param {object} props
 * @param {Game} props.game
 * @param {number} props.inningsNo
 */
export const BowlerSummaryV2 = ({ game, inningsNo }) => {
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;

  const battingPlayer = getBatterForInnings(game, inningsNo);
  const inningsColour = battingPlayer === "1" ? p2Colour : p1Colour;

  const bowlerLeadDiceUsed = innings.map((over) => getLeadBowlerForOver(over));

  const asHistogram = bowlerLeadDiceUsed.reduce(
    (histogram, dieId) => ({
      ...histogram,
      [dieId]: (histogram[dieId] || 0) + 1,
    }),
    {}
  );

  const wicketsPerOver = calculateWicketsForEachOver(game, inningsNo);
  const runsPerOver = calculateRunsForEachOver(game, inningsNo);
  const ballsPerOver = calculateBallsForEachOver(game, inningsNo);

  const wicketsHistogram = innings.reduce((wickets, over, i) => {
    const bowler = getLeadBowlerForOver(over);

    const wicketsInOver = wicketsPerOver[i];

    return {
      ...wickets,
      [bowler]: (wickets[bowler] || 0) + wicketsInOver,
    };
  }, {});

  const bowlers = Object.keys(asHistogram);

  const runsHistogram = innings.reduce((runs, over, i) => {
    const bowler = getLeadBowlerForOver(over);

    const runsInOver = runsPerOver[i];

    return {
      ...runs,
      [bowler]: (runs[bowler] || 0) + runsInOver,
    };
  }, {});

  const ballsHistogram = innings.reduce((balls, over, i) => {
    const bowler = getLeadBowlerForOver(over);

    const ballsInOver = ballsPerOver[i];

    return {
      ...balls,
      [bowler]: (balls[bowler] || 0) + ballsInOver,
    };
  }, {});

  const maidenHistogram = innings.reduce((maidens, over, i) => {
    const bowler = getLeadBowlerForOver(over);

    const maidensInOver = runsPerOver[i] === 0 ? 1 : 0;

    return {
      ...maidens,
      [bowler]: (maidens[bowler] || 0) + maidensInOver,
    };
  }, {});

  return (
    <Card style={{ display: "flex", flex: 1 }}>
      <Stack direction="column" display="flex" flex="1">
        <Stack
          direction="row"
          p={1}
          display="flex"
          justifyContent="space-between"
          style={{ backgroundColor: inningsColour, color: "white" }}
        >
          <Box flex={2}>
            <Typography variant="body2" textAlign="left">
              BOWLER
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body2" textAlign="center">
              O
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body2" textAlign="center">
              M
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body2" textAlign="center">
              R
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body2" textAlign="center">
              W
            </Typography>
          </Box>
        </Stack>
        {bowlers.map((bowlerId, i) => {
          const die = dice.find((die) => die.id === bowlerId);
          if (!die) {
            return null;
          }

          return (
            <Stack
              direction="row"
              key={bowlerId}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}
            >
              <Box flex={2}>
                <DieDisplay id={die.id} faces={die.faces} type={die.type} />
              </Box>
              <Box flex={1}>
                <Typography variant="body2" textAlign="center">
                  {formatBalls(ballsHistogram[bowlerId])}
                </Typography>
              </Box>
              <Box flex={1}>
                <Typography variant="body2" textAlign="center">
                  {maidenHistogram[bowlerId]}
                </Typography>
              </Box>
              <Box flex={1}>
                <Typography variant="body2" textAlign="center">
                  {runsHistogram[bowlerId]}
                </Typography>
              </Box>
              <Box flex={1}>
                <Typography variant="body2" textAlign="center">
                  {wicketsHistogram[bowlerId]}
                </Typography>
              </Box>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
};
