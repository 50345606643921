import React, { useEffect, useState } from "react";
import { Box, Button, Card, Stack } from "@mui/material";
import { useGameIdFromRoute, usePlayerFromRoute } from "../util/route-queries";
import { getSelectionsForPlayer } from "../models/game";
import { useSetOver, useStartPlay } from "../queries/games";
import { TeamDice } from "./TeamDice";
import { calculatePartnerships } from "../models/partnerships";

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const BatterDisplay = ({ game }) => {
  const gameId = useGameIdFromRoute();
  const player = usePlayerFromRoute();
  const selectionsBatter = getSelectionsForPlayer(game, player);
  const setOver = useSetOver(gameId, player);
  const startPlay = useStartPlay(gameId);

  const [selectedBatterDice, setSelectedBatterDice] = useState(game.batterOverDiceSelection);
  const [maxBatterDice, setMaxDice] = useState(5);

  const innings = game.innings === 1 ? game.firstInnings : game.secondInnings;
  const partnerships = calculatePartnerships(innings);
  const wickets = partnerships.filter(({ out }) => out).length;

  useEffect(() => {
    setSelectedBatterDice(game.batterOverDiceSelection);
  }, [game.over, game.batterLockedDice, game.batterOverDiceSelection]);

  useEffect(() => {
    const partnership = partnerships.find(({ id }) => id === wickets + 1);
    if (!partnership) {
      return;
    }

    setMaxDice(partnership.maxDice);
  }, [wickets, partnerships]);

  /**
   * @param {import("../types/type").DieId} id
   */
  const selectBatterDie = (id) => {
    if (selectedBatterDice.includes(id)) {
      setSelectedBatterDice((existing) => existing.filter((dieId) => dieId !== id));
      return;
    }

    if (selectedBatterDice.length === maxBatterDice) {
      return;
    }

    setSelectedBatterDice((existing) => existing.concat(id));
  };

  const playOver = () => {
    setOver.mutate({ selections: selectedBatterDice, bowlerAttackDice: [] });
  };

  const onStartNextInningsHandler = () => {
    startPlay.mutate();
  };

  return (
    <Box>
      {!game.inningsComplete && (
        <Box pt={0}>
          <TeamDice
            title={`YOU ARE BATTING: ${selectedBatterDice.length} OF ${maxBatterDice} DICE`}
            selections={selectionsBatter}
            selectedDice={selectedBatterDice}
            selectBatterDie={selectBatterDie}
            isBowler={false}
            disabled={game.batterHasSelectedDice}
            preselectedDice={game.batterOverDiceSelection}
            lockedDice={game.batterLockedDice}
          />
        </Box>
      )}

      <Box pt={1} style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1 }}>
        <Card>
          <Box display="flex" justifyContent="center" p={2}>
            <Stack direction="row" display="flex" justifyContent="space-between">
              <Box pr={1}>
                {!game.inningsComplete ? (
                  <Button variant="contained" disabled={game.batterHasSelectedDice} onClick={playOver}>
                    SELECT
                  </Button>
                ) : (
                  <Button onClick={onStartNextInningsHandler} variant="contained" disabled={player === "2"}>
                    NEXT
                  </Button>
                )}
              </Box>
            </Stack>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
