export const RunsPerBonusChance = 50;

/**
 * @param {Partnership[]} partnerships
 * @param {number} chancesLost
 * @returns {number}
 */
export const mapChancesToWickets = (partnerships, chancesLost) => {
  if (chancesLost === 0) {
    return 0;
  }

  const withBonusChances = partnerships.map((p) => ({
    ...p,
    chances: p.chances + Math.floor(p.runs / RunsPerBonusChance),
  }));

  const [wicket, ,] = withBonusChances.reduce(
    ([wicket, sumChances, complete], partnership) => {
      if (complete) {
        return [wicket, 0, complete];
      }
      const threshold = sumChances + partnership.chances;
      if (chancesLost < threshold) {
        return [partnership.id - 1, 0, true];
      }

      return [partnership.id, sumChances + partnership.chances, false];
    },
    [0, 0, false]
  );

  return wicket;
};
