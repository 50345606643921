import { ballByBall } from "./ball-by-ball";
import { Dot } from "./dice";
import { makeInitialBattingPartnerships } from "./partnerships";

/**
 * @param {Game} game
 * @returns {boolean}
 */
export const haveBothPlayerSelectedTeams = (game) => {
  const p1Ready = game.player1Selections.length === 11;
  const p2Ready = game.player2Selections.length === 11;
  return p1Ready && p2Ready;
};

/**
 * @param {Game} game
 * @param {Player} player
 * @returns {import("../types/type").DieId[]}
 */
export const getSelectionsForPlayer = (game, player) => {
  return player === "1" ? game.player1Selections : game.player2Selections;
};

/**
 * @param {Game} game
 * @returns {Over[]}
 */
export const getCurrentInnings = (game) => (game.innings === 1 ? game.firstInnings : game.secondInnings);

/**
 * @param {Game} game
 * @returns {number}
 */
export const getOversBowled = (game) => {
  return getCurrentInnings(game).length;
};

export const MinDicePerOver = {
  6: 2,
  5: 3,
  4: 4,
  3: 5,
  2: 6,
};

/**
 * @param {DiceRoll} roll
 */
// @ts-ignore
const isNotX = (roll) => !["XXX", "XX", "X"].includes(roll.face);

/**
 * @param {DiceRoll} roll
 */
// @ts-ignore
const isX = (roll) => ["XXX", "XX", "X"].includes(roll.face);

/**
 * @param {DiceRoll} roll
 */
// @ts-ignore
const isNotDot = (roll) => ![Dot].includes(roll.face);

/**
 * @param {DiceRoll} roll
 */
const isNotCancelled = (roll) => !roll.cancelled;

/**
 * @param {DiceRoll} roll
 */
const isCancelled = (roll) => roll.cancelled;

/**
 * @param {DiceRoll} roll
 */
const isScoring = (roll) => isNotX(roll) && isNotDot(roll);

/**
 * @param {Over[]} innings
 */
export const runsFromBatter = (innings) =>
  innings
    .map((over) =>
      (over.batterRollsv2 || [])
        .filter(isNotCancelled)
        .filter(isScoring)
        .map((roll) => roll.face)
        // @ts-ignore
        .reduce((t, v) => t + v, 0)
    )
    .reduce((t, v) => t + v, 0);

/**
 * @param {Over[]} innings
 */
export const chancesFromBatter = (innings) =>
  innings
    .map((over) =>
      (over.batterRollsv2 || [])
        .filter(isNotCancelled)
        .filter(isX)
        .map((roll) => roll.face)
        .join("")
    )
    .join("").length;

/**
 * @param {Over[]} innings
 */
export const chancesFromBowler = (innings) =>
  innings
    .map((over) =>
      (over.bowlerAtackRollsv2 || [])
        .filter(isNotCancelled)
        .filter(isX)
        .map((roll) => roll.face)
        .join("")
    )
    .join("").length;

/**
 * @param {Over[]} innings
 */
export const runsFromAttack = (innings) =>
  innings
    .map((over) =>
      (over.bowlerAtackRollsv2 || [])
        .filter(isNotCancelled)
        .filter(isScoring)
        .map((roll) => roll.face)
        // @ts-ignore
        .reduce((t, v) => t + v, 0)
    )
    .reduce((t, v) => t + v, 0);

/**
 * @param {Over[]} innings
 */
export const runsFromDefence = (innings) =>
  innings
    .map((over) =>
      (over.bowlerRollsv2 || [])
        .filter(isNotCancelled)
        .filter(isScoring)
        .map((roll) => roll.face)
        // @ts-ignore
        .reduce((t, v) => t + v, 0)
    )
    .reduce((t, v) => t + v, 0);

/**
 * @param {Over[]} innings
 */
export const savedRuns = (innings) =>
  innings
    .map((over) =>
      (over.bowlerRollsv2 || [])
        .filter(isCancelled)
        .filter(isScoring)
        .map((roll) => roll.face)
        // @ts-ignore
        .reduce((t, v) => t + v, 0)
    )
    .reduce((t, v) => t + v, 0);

/**
 * @param {BattingPartnership[]} partnerships
 * @returns {number}
 */
export const calculateScore = (partnerships) => partnerships.reduce((t, p) => t + p.runs, 0);

/**
 * @param {BattingPartnership[]} partnerships
 * @returns {number}
 */
export const calculateBalls = (partnerships) => partnerships.reduce((t, p) => t + p.balls, 0);

/**
 * @param {BattingPartnership[]} partnerships
 * @returns {number}
 */
export const calculateWickets = (partnerships) => partnerships.filter((p) => p.out).length;

/**
 * @param {Game} game
 * @returns {number|undefined}
 */
export const getTarget = (game) => {
  if (game.innings === 1) {
    return undefined;
  }

  const team = getBattingTeam(game);
  const initial = makeInitialBattingPartnerships(team);
  const partnerships = ballByBall(initial, game.firstInnings, undefined);

  return calculateScore(partnerships) + 1;
};

/**
 * @param {Game} game
 * @returns {import("../types/type").DieId[]}
 */
export const getBattingTeam = (game) => {
  const player = getPlayer(game, game.innings);

  return player === "1" ? game.player1Selections : game.player2Selections;
};

/**
 * @param {Game} game
 * @param {Player} player
 * @returns {import("../types/type").DieId[]}
 */
export const getTeam = (game, player) => {
  return player === "1" ? game.player1Selections : game.player2Selections;
};

/**
 * @param {BattingPartnership[]} partnerships
 * @param {import("../types/type").DieId} dieId
 * @returns {Batter}
 */
export const findScoreForPlayer = (partnerships, dieId) => {
  const batters = partnerships.map((p) => [p.batter1, p.batter2]).reduce((all, arr) => [...all, ...arr], []);

  // @ts-ignore
  const forBatter = batters.filter(Boolean).filter((b) => b.id === dieId);
  // @ts-ignore
  return forBatter.reverse()[0];
};

/**
 * Find the batting player for a particular inning
 * @param {Game} game
 * @param {number} innings
 * @returns {Player}
 */
export const getPlayer = (game, innings) => {
  if (game.config.batterForInningsKnown) {
    return innings === 1 ? game.batter1st : game.batter2nd;
  }

  if (game.battingPlayer === "1" && game.innings === 1) {
    return innings === 1 ? "1" : "2";
  }
  if (game.battingPlayer === "1" && game.innings === 2) {
    return innings === 1 ? "2" : "1";
  }
  if (game.battingPlayer === "2" && game.innings === 1) {
    return innings === 1 ? "2" : "1";
  }
  if (game.battingPlayer === "1" && game.innings === 2) {
    return innings === 1 ? "1" : "2";
  }

  return "1";
};

/**
 * Find the batting player for a particular inning
 * @param {Game} game
 * @param {number} innings
 * @returns {Player}
 */
export const getBowlingPlayer = (game, innings) => {
  if (game.battingPlayer === "1" && game.innings === 1) {
    return innings === 1 ? "1" : "2";
  }
  if (game.battingPlayer === "1" && game.innings === 2) {
    return innings === 1 ? "2" : "1";
  }
  if (game.battingPlayer === "2" && game.innings === 1) {
    return innings === 1 ? "2" : "1";
  }
  if (game.battingPlayer === "1" && game.innings === 2) {
    return innings === 1 ? "1" : "2";
  }

  return "1";
};

/**
 *
 * @param {import("../types/type").DieId[]} team
 * @param {Over[]} innings
 * @returns {String}
 */
export const calculateOverScore = (team, innings, no) => {
  const toPriorOver = ballByBall(makeInitialBattingPartnerships(team), no === 0 ? [] : innings.slice(0, no));
  const forOver = ballByBall(makeInitialBattingPartnerships(team), innings.slice(0, no + 1));

  const wicketsPrior = calculateWickets(toPriorOver);
  const wicketsThis = calculateWickets(forOver);

  const runsPrior = calculateScore(toPriorOver);
  const runsThis = calculateScore(forOver);

  return `${wicketsThis - wicketsPrior}/${runsThis - runsPrior}`;
};

export const getBatterForInnings = getPlayer;
