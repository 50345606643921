import React, { useEffect, useState } from "react";
import { Box, Button, Card, Stack } from "@mui/material";
import { useGameIdFromRoute, usePlayerFromRoute } from "../util/route-queries";
import { MinDicePerOver, getOversBowled, getSelectionsForPlayer } from "../models/game";
import { TeamDice } from "./TeamDice";
import { useSetOver, useStartPlay } from "../queries/games";

const formatDice = (dice) => {
  if (dice === 0) {
    return "NO DICE SELECTED";
  }
  if (dice === 1) {
    return "1 DIE SELECTED";
  }

  return `${dice} DICE SELECTED`;
};

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const BowlerDisplay = ({ game }) => {
  const gameId = useGameIdFromRoute();
  const player = usePlayerFromRoute();
  const selectionsBowler = getSelectionsForPlayer(game, player);
  const startPlay = useStartPlay(gameId);

  const [attackBowlerDice, setAttackBowlerDice] = useState(game.bowlerOverAttackDice);
  const [selectedBowlerDice, setSelectedBowlerDice] = useState(game.bowlerOverDiceSelection);
  const [playOverButtonDisabled, setPlayOverButtonDisabled] = useState(true);
  const [playOverButtonDisabledReason, setPlayOverButtonDisabledReason] = useState("LOADING");

  const setOver = useSetOver(gameId, player);

  useEffect(() => {
    setAttackBowlerDice(game.bowlerOverAttackDice);
    setSelectedBowlerDice(game.bowlerOverDiceSelection);
  }, [game.bowlerOverAttackDice, game.bowlerOverDiceSelection, game.bowlerLockedDice]);

  useEffect(() => {
    if (game.bowlerHasSelectedDice) {
      setPlayOverButtonDisabled(true);
      setPlayOverButtonDisabledReason("WAITING FOR OPPONENT");
      return;
    }

    const bowlerDiceCount = selectedBowlerDice.length;
    const remainingOvers =
      MinDicePerOver[bowlerDiceCount] - game.diceUsedPerOver.filter((qty) => qty === bowlerDiceCount).length;
    const usedOvers6 = game.diceUsedPerOver.filter((qty) => qty === 6).length;
    const mustBowl6 = usedOvers6 === 0 && getOversBowled(game) === 17 && bowlerDiceCount !== 6;

    setPlayOverButtonDisabled(bowlerDiceCount < 2 || remainingOvers === 0 || mustBowl6);

    if (mustBowl6) {
      setPlayOverButtonDisabledReason(`DICE MUST BE 6`);
      return;
    }
    if (bowlerDiceCount < 2) {
      setPlayOverButtonDisabledReason("MINIMUM 2 DICE");
      return;
    }
    if (remainingOvers === 0) {
      setPlayOverButtonDisabledReason(`DICE COUNT CAN'T BE ${bowlerDiceCount}`);
      return;
    }

    setPlayOverButtonDisabledReason("");
  }, [selectedBowlerDice, game]);

  /**
   * @param {import("../types/type").DieId} id
   */
  const selectBowlerDieWhenFirstDieIsOnlyAttack = (id) => {
    const leadDie = attackBowlerDice[0];

    if (selectedBowlerDice.includes(id) && id === leadDie) {
      // deselect all
      setAttackBowlerDice([]);
      setSelectedBowlerDice([]);
      return;
    }
    if (selectedBowlerDice.includes(id)) {
      // deselect non-lead
      setSelectedBowlerDice((existing) => existing.filter((dieId) => dieId !== id));
      return;
    }
    if (selectedBowlerDice.length === 6) {
      return;
    }

    if (selectedBowlerDice.length === 0) {
      setAttackBowlerDice([id]);
    }

    setSelectedBowlerDice((existing) => existing.concat(id));
  };

  /**
   * @param {import("../types/type").DieId} id
   */
  const selectBowlerDie = (id) => {
    if (game.config.bowlerFirstDieIsOnlyAttack) {
      return selectBowlerDieWhenFirstDieIsOnlyAttack(id);
    }

    if (selectedBowlerDice.includes(id)) {
      setSelectedBowlerDice((existing) => existing.filter((dieId) => dieId !== id));
      return;
    }

    if (selectedBowlerDice.length === 6) {
      return;
    }

    setSelectedBowlerDice((existing) => existing.concat(id));
  };

  /**
   * @param {import("../types/type").DieId} id
   */
  const toggleAttackDie = (id) => {
    if (game.config.bowlerFirstDieIsOnlyAttack) {
      return;
    }

    if (attackBowlerDice.includes(id)) {
      setAttackBowlerDice((existing) => existing.filter((dieId) => dieId !== id));
    } else {
      setAttackBowlerDice((existing) => existing.concat(id));
    }
  };

  const playOver = () => {
    setOver.mutate({ selections: selectedBowlerDice, bowlerAttackDice: attackBowlerDice });
  };

  const onStartNextInningsHandler = () => {
    startPlay.mutate();
  };

  return (
    <Box>
      {!game.inningsComplete && (
        <Box pt={0}>
          <TeamDice
            title={`YOU ARE BOWLING (${formatDice(selectedBowlerDice.length)})`}
            selections={selectionsBowler}
            selectBatterDie={selectBowlerDie}
            disabled={game.bowlerHasSelectedDice}
            lockedDice={game.bowlerLockedDice}
            selectedDice={selectedBowlerDice}
            attackDice={attackBowlerDice}
            isBowler
            toggleAttackDie={toggleAttackDie}
            preselectedDice={[]}
            leadDiceUsed={game.bowlerLeadDiceUsed}
            bowlerFirstDieIsOnlyAttack={game.config.bowlerFirstDieIsOnlyAttack}
            bowlerMax4OversAsAttackDie={game.config.bowlerMax4OversAsAttackDie}
          />
        </Box>
      )}

      <Box pt={1} style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1 }}>
        <Card>
          <Box display="flex" justifyContent="center" p={2}>
            <Stack direction="column">
              {!game.inningsComplete ? (
                <Button variant="contained" disabled={playOverButtonDisabled} onClick={playOver}>
                  {playOverButtonDisabled ? playOverButtonDisabledReason : "PLAY OVER"}
                </Button>
              ) : (
                <Button onClick={onStartNextInningsHandler} variant="contained" disabled={player === "2"}>
                  NEXT
                </Button>
              )}
            </Stack>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
