import React from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { p1Colour, p2Colour } from "./colors";
import { runsBreakdownOptions } from "./options";
import { buildBarChartData } from "./buildBarChartData";

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const RunsBreakdown = ({ game }) => {
  const firstInningsColour = game.battingPlayer === "1" ? p2Colour : p1Colour;
  const secondInningsColour = game.battingPlayer === "1" ? p1Colour : p2Colour;

  const barData = buildBarChartData(game, firstInningsColour, secondInningsColour);

  return (
    <Card>
      <Box p={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">RUNS BREAKDOWN</Typography>
          <Stack direction="row">
            <Box
              p={1}
              style={{
                height: 4,
                width: 4,
                borderStyle: "solid",
                borderColor: p1Colour,
                backgroundColor: p1Colour,
              }}
            />
            <Box pl={1}>
              <Typography>Player 1</Typography>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box
              p={1}
              style={{
                height: 4,
                width: 4,
                borderStyle: "solid",
                borderColor: "orange",
                backgroundColor: "orange",
              }}
            />
            <Box pl={1}>
              <Typography>Bowler Attack</Typography>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box
              p={1}
              style={{
                height: 4,
                width: 4,
                borderStyle: "solid",
                borderColor: p2Colour,
                backgroundColor: p2Colour,
              }}
            />
            <Box pl={1}>
              <Typography>Player 2</Typography>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box
              p={1}
              style={{
                height: 4,
                width: 4,
                borderStyle: "solid",
                borderColor: "grey",
                backgroundColor: "grey",
              }}
            />
            <Box pl={1}>
              <Typography>Runs Saved</Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ mt: 3, height: 200 }}>
        <Bar
          // @ts-ignore
          data={barData}
          // @ts-ignore
          options={runsBreakdownOptions}
        />
      </Box>
    </Card>
  );
};
