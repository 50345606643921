import React from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { calculatePartnerships } from "../models/partnerships";
import { p1Colour, p2Colour } from "./colors";
import duck from "../assets/duck.png";

export const BattingSummary = ({ game }) => {
  const partnerships = calculatePartnerships(game.firstInnings);
  const score = game.firstInnings.reduce((t, over) => t + over.runs, 0);
  const wickets = partnerships.filter(({ out }) => out).length;
  const playerColour = game.battingPlayer === "1" ? p1Colour : p2Colour;

  return (
    <Card style={{ display: "flex", flex: 1 }}>
      <Stack direction="column" display="flex" flex="1">
        <Stack direction="row" p={1} display="flex" justifyContent="center" style={{ backgroundColor: playerColour }}>
          <Typography variant="h6" textAlign="center" color="white">
            {game.battingPlayer === "1" ? "PLAYER 1" : "PLAYER 2"}
          </Typography>
        </Stack>
        {partnerships.map(({ id, runs, out }, i) => {
          const isNotOutBatter = wickets + 1 === id;
          const DNB = !out && !isNotOutBatter;
          const isDuck = out && runs === 0;

          return (
            <Stack
              direction="row"
              key={id}
              p={1}
              display="flex"
              justifyContent="space-between"
              style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}
            >
              <Box flex={1}>
                <Typography>{id}</Typography>
              </Box>
              <Box flex={1}>
                {isDuck ? (
                  <Typography textAlign="right">
                    <img src={duck} alt="duck" style={{ width: 16, height: 16 }} />
                  </Typography>
                ) : (
                  <Typography textAlign="right">{`${DNB ? "" : runs}${!out && isNotOutBatter ? "*" : ""}`}</Typography>
                )}
              </Box>
              <Box flex={1}>
                <Typography textAlign="right">
                  {out ? `${id}/${partnerships.filter((p) => p.id <= id).reduce((t, p) => t + p.runs, 0)}` : ""}
                </Typography>
              </Box>
            </Stack>
          );
        })}
        <Stack direction="row" p={1} display="flex" justifyContent="center" style={{ backgroundColor: p1Colour }}>
          <Typography variant="h6" textAlign="center" color="white">
            {wickets}/{score} ({game.firstInnings.length})
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
