import endpoints from "./endpoints";
import { get, optionsAuth, post } from "./network";

/**
 * @param {import("../types/type").TokenString} token
 * @returns {Promise<PrivateUserProfile>}
 */
export const fetchMyProfile = async (token) => {
  const { users } = endpoints();
  const url = users().me();

  const response = await get(url, optionsAuth(token));

  return response.data;
};

/**
 * @param {import("../types/type").TokenString} token
 * @param {import("../types/type").UserId} userId
 * @returns {Promise<PrivateUserProfile>}
 */
export const fetchUserProfile = async (token, userId) => {
  const { users } = endpoints();
  const url = users().user(userId).get();

  const response = await get(url, optionsAuth(token));

  return response.data;
};

/**
 * @param {import("../types/type").TokenString} token
 * @param {string} shortName
 * @returns {Promise<PrivateUserProfile>}
 */
export const updateUserProfile = async (token, shortName) => {
  const { users } = endpoints();
  const url = users().me();

  const body = {
    shortName,
  };

  const response = await post(url, body, optionsAuth(token));

  return response.data;
};
