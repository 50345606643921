/**
 * @param {string} s
 * @returns {import("../types/type").TokenString}
 */
// @ts-ignore
export const s2Token = (s) => s;

/**
 * @param {string} s
 * @returns {import("../types/type").UserId}
 */
// @ts-ignore
export const s2UserId = (s) => s;

/**
 * @param {string} s
 * @returns {import("../types/type").GameId}
 */
// @ts-ignore
export const s2GameId = (s) => s;

/**
 * @param {string} x
 * @returns {import("../types/type").DieId}
 */
export const s2DieId = (x) => {
  // @ts-ignore
  return x;
};

/**
 * @param {number} x
 * @returns {import("../types/type").PartnershipId}
 */
export const s2PartnershipId = (x) => {
  // @ts-ignore
  return x;
};
