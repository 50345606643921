import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import { options } from "./options";
import { buildChartData, buildChartDataV2 } from "./buildChartData";

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const Scoreworm = ({ game }) => {
  const dataV1 = buildChartData(game.firstInnings, game.secondInnings);
  const dataV2 = buildChartDataV2(game);

  const data = game.config.useIndividualBallTracking ? dataV2 : dataV1;

  return (
    <Card>
      <Box p={1}>
        <Typography variant="body2">SCOREWORM</Typography>
      </Box>
      <Box sx={{ mt: 3, height: 300 }}>
        <Line
          // @ts-ignore
          data={data}
          // @ts-ignore
          options={options}
        />
      </Box>
    </Card>
  );
};
