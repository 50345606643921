export const p1Colour = "#E83F6F";
export const p2Colour = "#2274A5";

/**
 * @param {("1"|"2"|undefined)} player
 */
export const playerColour = (player) => (player === "1" ? p1Colour : p2Colour);

export const colors = {
  white: {
    text: "#222",
    color: "#E5DADA",
  },
  yellow: {
    text: "#222",
    color: "#F1F500",
  },
  orange: {
    text: "#222",
    color: "#E59500",
  },
  red: {
    text: "#fff",
    color: "#B80046",
  },
};
