import React from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { dice } from "../models/dice";
import { Die } from "./Die";
import { playerColour } from "./colors";
import { usePlayerFromRoute } from "../util/route-queries";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {import("../types/type").DieId[]} props.selections
 * @param {Function} props.selectBatterDie
 * @param {boolean} props.isBowler
 * @param {boolean} props.disabled
 * @param {boolean} props.bowlerFirstDieIsOnlyAttack
 * @param {boolean} props.bowlerMax4OversAsAttackDie
 * @param {import("../types/type").DieId[]} props.lockedDice
 * @param {import("../types/type").DieId[]} props.selectedDice
 * @param {import("../types/type").DieId[]} props.preselectedDice
 * @param {import("../types/type").DieId[]} props.attackDice
 * @param {import("../types/type").DieId[]} props.leadDiceUsed
 * @param {Function} props.toggleAttackDie
 */
export const TeamDice = ({
  title,
  selections,
  selectBatterDie,
  isBowler,
  disabled,
  lockedDice = [],
  selectedDice = [],
  preselectedDice = [],
  attackDice = [],
  leadDiceUsed = [],
  toggleAttackDie,
  bowlerFirstDieIsOnlyAttack,
  bowlerMax4OversAsAttackDie,
}) => {
  const player = usePlayerFromRoute();

  return (
    <Card>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Box p={1} style={{ backgroundColor: playerColour(player), width: "100%" }}>
          <Typography variant="body2" textAlign="center" color="white">
            {title}
          </Typography>
        </Box>
        <Grid container>
          {selections.map((dieId) => {
            const die = dice.find((die) => die.id === dieId);
            if (!die) {
              return null;
            }

            const timesUsed = leadDiceUsed.filter((lead) => lead === dieId).length;

            return (
              <Grid item xs={6} key={die.id}>
                <Die
                  isBowler={isBowler}
                  showAttack={isBowler && selectedDice.includes(dieId)}
                  isAttack={isBowler && attackDice.includes(dieId)}
                  toggleAttackDie={toggleAttackDie}
                  small
                  id={die.id}
                  faces={die.faces}
                  type={die.type}
                  selectDie={selectBatterDie}
                  disabled={disabled}
                  preselected={preselectedDice.includes(dieId)}
                  selected={selectedDice.includes(dieId)}
                  inaccessible={lockedDice.includes(dieId)}
                  noIcon={isBowler}
                  isLeadDie={bowlerFirstDieIsOnlyAttack && attackDice.includes(dieId)}
                  leadSelected={bowlerFirstDieIsOnlyAttack && attackDice.length > 0}
                  timesUsed={timesUsed}
                  bowlerMax4OversAsAttackDie={bowlerMax4OversAsAttackDie}
                  hasSelectedLead={attackDice.length > 0}
                />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Card>
  );
};
