import React from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { calculatePartnerships, makeInitialBattingPartnerships } from "../models/partnerships";
import duck from "../assets/duck.png";
import { p1Colour, p2Colour } from "./colors";
import { ballByBall } from "../models/ball-by-ball";
import {
  calculateBalls,
  calculateScore,
  calculateWickets,
  findScoreForPlayer,
  getPlayer,
  getTarget,
} from "../models/game";
import { dice } from "../models/dice";
import { DieDisplay } from "./Die";
import { formatBalls } from "./formatBalls";

const makeGold = "invert(63%) sepia(90%) saturate(3496%) hue-rotate(14deg) brightness(101%) contrast(101%)";

/**
 * @param {object} props
 * @param {Game} props.game
 * @param {string} props.title
 * @param {number} props.inningsNo
 */
export const InningsSummaryV2 = ({ game, title, inningsNo }) => {
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;
  const target = getTarget(game);

  const player = getPlayer(game, inningsNo);
  const team = player === "1" ? game.player1Selections : game.player2Selections;
  const initial = makeInitialBattingPartnerships(team);
  const partnerships = ballByBall(initial, innings, target);

  const inningsColour = player === "1" ? p1Colour : p2Colour;

  return (
    <Card style={{ display: "flex", flex: 1 }}>
      <Stack direction="column" display="flex" flex="1">
        <Stack direction="row" p={1} display="flex" justifyContent="center" style={{ backgroundColor: inningsColour }}>
          <Typography variant="h6" textAlign="center" color="white">
            {title}
          </Typography>
        </Stack>
        {team.map((id, i) => {
          const score = findScoreForPlayer(partnerships, id);
          const { out } = score;
          const isNotOutBatter = !score.out;
          const DNB = !out && !isNotOutBatter;
          const isDuck = out && score.runs === 0;
          const die = dice.find((die) => die.id === id);
          if (!die) {
            return null;
          }

          return (
            <Stack
              direction="row"
              key={id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}
            >
              <Box flex={1}>
                <DieDisplay id={die.id} verysmall faces={die.faces} type={die.type} />
              </Box>
              {score.balls > 0 && (
                <Box flex={1} pr={1}>
                  {isDuck ? (
                    <Typography textAlign="right">
                      <img
                        src={duck}
                        alt="duck"
                        style={{
                          width: 16,
                          height: 16,
                          filter: score.balls === 1 ? makeGold : "",
                        }}
                      />{" "}
                      ({score.balls})
                    </Typography>
                  ) : (
                    <Typography textAlign="right">{`${DNB ? "" : score.runs}${!out && isNotOutBatter ? "*" : ""} (${
                      score.balls
                    })`}</Typography>
                  )}
                </Box>
              )}
            </Stack>
          );
        })}
        <Stack direction="row" p={1} display="flex" justifyContent="center" style={{ backgroundColor: inningsColour }}>
          <Typography variant="h6" textAlign="center" color="white">
            {calculateWickets(partnerships) < 10 && `${calculateWickets(partnerships)}/`}
            {calculateScore(partnerships)} ({formatBalls(calculateBalls(partnerships))})
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

/**
 * @param {object} props
 * @param {Game} props.game
 * @param {string} props.title
 * @param {number} props.inningsNo
 */
export const InningsSummary = ({ game, title, inningsNo }) => {
  const innings = inningsNo === 1 ? game.firstInnings : game.secondInnings;
  const partnerships1 = calculatePartnerships(innings);
  const p1Score = innings.reduce((t, over) => t + over.runs, 0);
  const p1Wickets = partnerships1.filter(({ out }) => out).length;

  const player = getPlayer(game, inningsNo);
  const inningsColour = player === "1" ? p1Colour : p2Colour;

  return (
    <Card style={{ display: "flex", flex: 1 }}>
      <Stack direction="column" display="flex" flex="1">
        <Stack direction="row" p={1} display="flex" justifyContent="center" style={{ backgroundColor: inningsColour }}>
          <Typography variant="h6" textAlign="center" color="white">
            {title}
          </Typography>
        </Stack>
        {partnerships1.map(({ id, runs, balls, out }, i) => {
          const isNotOutBatter = p1Wickets + 1 === id;
          const DNB = !out && !isNotOutBatter;
          const isDuck = out && runs === 0;

          return (
            <Stack
              direction="row"
              key={id}
              p={1}
              display="flex"
              justifyContent="space-between"
              style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#eee" }}
            >
              <Box flex={1}>
                <Typography>{id}</Typography>
              </Box>
              <Box flex={1}>
                {isDuck ? (
                  <Typography textAlign="right">
                    <img src={duck} alt="duck" style={{ width: 16, height: 16 }} />
                  </Typography>
                ) : (
                  <Typography textAlign="right">{`${DNB ? "" : runs}${
                    !out && isNotOutBatter ? "*" : ""
                  } (${balls})`}</Typography>
                )}
              </Box>
              <Box flex={1}>
                <Typography textAlign="right">
                  {out ? `${id}/${partnerships1.filter((p) => p.id <= id).reduce((t, p) => t + p.runs, 0)}` : ""}
                </Typography>
              </Box>
            </Stack>
          );
        })}
        <Stack direction="row" p={1} display="flex" justifyContent="center" style={{ backgroundColor: inningsColour }}>
          <Typography variant="h6" textAlign="center" color="white">
            {p1Wickets < 10 && `${p1Wickets}/`}
            {p1Score} ({innings.length})
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
