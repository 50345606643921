import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { p1Colour, p2Colour } from "./colors";
import { chancesFromBatter, chancesFromBowler } from "../models/game";
import { chancesOptions } from "./options";

/**
 * @param {Game} game
 */
export const buildBarChartData = (game) => {
  const firstInningsColour = game.battingPlayer === "1" ? p2Colour : p1Colour;
  const secondInningsColour = game.battingPlayer === "1" ? p1Colour : p2Colour;

  /**
   * @type {import("chart.js").ChartData<"bar", number[], string>}
   */
  const barData = {
    datasets: [
      {
        type: "bar",
        data: [chancesFromBatter(game.firstInnings), chancesFromBatter(game.secondInnings)],
        label: "Chances from Batter",
        backgroundColor: [firstInningsColour, secondInningsColour],
      },
      {
        type: "bar",
        data: [chancesFromBowler(game.firstInnings), chancesFromBowler(game.secondInnings)],
        label: "Chances from Bowler",
        backgroundColor: [secondInningsColour, firstInningsColour],
      },
    ],
    labels: ["First Innings", "Second Innings"],
  };

  return barData;
};

/**
 * @param {object} props
 * @param {Game} props.game
 */
export const Chances = ({ game }) => {
  const barData = buildBarChartData(game);

  return (
    <Card>
      <Box p={1}>
        <Box pb={1}>
          <Typography variant="body2">CHANCES</Typography>
        </Box>
        <Box sx={{ mt: 3, height: 200 }}>
          <Bar
            // @ts-ignore
            data={barData}
            // @ts-ignore
            options={chancesOptions}
          />
        </Box>
      </Box>
    </Card>
  );
};
