import { runsFromAttack, runsFromBatter, runsFromDefence, savedRuns } from "../models/game";

/**
 * @param {Game} game
 * @param {string} firstInningsColour
 * @param {string} secondInningsColour
 */
export const buildBarChartData = (game, firstInningsColour, secondInningsColour) => {
  const barData = {
    datasets: [
      {
        type: "bar",
        data: [runsFromBatter(game.firstInnings), runsFromBatter(game.secondInnings)],
        label: "Batter",
        backgroundColor: [firstInningsColour, secondInningsColour],
      },
      {
        type: "bar",
        data: [runsFromAttack(game.firstInnings), runsFromAttack(game.secondInnings)],
        label: "Bowler Attack",
        backgroundColor: "orange",
      },
      {
        type: "bar",
        data: [runsFromDefence(game.firstInnings), runsFromDefence(game.secondInnings)],
        label: "Bowler Defence",
        backgroundColor: [secondInningsColour, firstInningsColour],
      },
      {
        type: "bar",
        data: [savedRuns(game.firstInnings), savedRuns(game.secondInnings)],
        label: "Runs Saved",
        backgroundColor: "grey",
      },
    ],
    labels: ["First Innings", "Second Innings"],
  };

  return barData;
};
