import React from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import { PickTeams } from "./screens/PickTeams";
import { PlayGame } from "./screens/PlayGame";
import { InningsComplete } from "./screens/InningsComplete";
import { MatchComplete } from "./screens/MatchComplete";
import { useGameIdFromRoute, usePlayerFromRoute, usePlayerFromRouteWithUndefined } from "./util/route-queries";
import { PlayerSelection } from "./PlayerSelection";
import { useGame, usePickTeams } from "./queries/games";
import { CenterOnPage } from "./layout/CenterOnPage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUserProfile } from "./queries/user";

function App() {
  const player = usePlayerFromRoute();
  const gameId = useGameIdFromRoute();
  const pickTeams = usePickTeams(gameId, player);
  const { data: game } = useGame(gameId);

  /**
   * @param {import("./types/type").DieId[]} selections
   */
  const pickTeamsCompleteHandler = (selections) => {
    pickTeams.mutate(selections);
  };

  if (!game) {
    return (
      <Container style={{ padding: 0 }}>
        <Box p={4} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container style={{ padding: 0 }}>
      {game.phase === "PICK_TEAMS" && <PickTeams onPhaseComplete={pickTeamsCompleteHandler} />}
      {game.phase === "PLAY" && <PlayGame game={game} />}
      {game.phase === "INNINGS_RESULTS" && <InningsComplete game={game} />}
      {game.phase === "MATCH_RESULTS" && <MatchComplete game={game} />}
    </Container>
  );
}

const RolePicker = () => {
  const player = usePlayerFromRouteWithUndefined();
  const gameId = useGameIdFromRoute();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: game, isLoading: isGameLoading } = useGame(gameId);
  const { data: user, isLoading: isUserLoading } = useUserProfile();

  if (isGameLoading || isUserLoading) {
    return (
      <CenterOnPage>
        <CircularProgress />
      </CenterOnPage>
    );
  }

  if (!game || !user) {
    navigate("/new-game");
    return;
  }

  if (game.player1 === user.id && player !== "1") {
    setSearchParams({ player: "1" });
    return <App />;
  }
  if (game.player2 === user.id && player !== "2") {
    setSearchParams({ player: "2" });
    return <App />;
  }

  if (game.player1 !== user.id && player === "1") {
    setSearchParams({ player: "" });
    return <PlayerSelection />;
  }
  if (game.player2 !== user.id && player === "2") {
    setSearchParams({ player: "" });
    return <PlayerSelection />;
  }

  if (!player) {
    return <PlayerSelection />;
  }

  if (!["1", "2"].includes(player)) {
    return <PlayerSelection />;
  }

  return <App />;
};

export default RolePicker;
